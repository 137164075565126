import { post } from '@/utils/request'

export function getNavList() {
  return post('task/department/select')
}
export function getAddSave(data) {
  return post('task/save/addSave', data)
}
export function getInfoSave(data) {
  return post('task/save/infoSave', data)
}