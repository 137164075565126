import { post } from '@/utils/request'
//聊天列表
export function getList(data) {
    return post('admin/taskchat/listChat', data)
}
// 发信息
export function getAdd(data) {
    return post('admin/taskchat/addChat', data)
}
//消息撤回
export function getWithdraw(data) {
    return post('admin/taskchat/withdraw', data)
}
//消息修改
export function getEdit(data) {
    return post('admin/taskchat/editChat', data)
}
export function getms() {
    return post('admin/user/ceshi')
}

export function getAt(data) {
    return post('admin/taskchat/getEit', data)
}
// 下载文件

export function downloadFile(data) {
    return post('admin/fileupload/downloadFile', data)
}
