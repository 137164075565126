<template>
  <div :class="['dialog-box', 'analysis-dialog']">
    <el-dialog
      :modal-append-to-body="false"
      :title="`预览 - ${file.title}`"
      :visible.sync="file.dialogVisible"
      :before-close="handleClose"
      top="0"
    >
      <div
        v-viewer
        style="height:100%;width: 100%;display: flex;justify-self: center;align-items: center;"
      >
        <iframe
          v-if="fileType === 'notimg' || fileType === 'pdf'"
          class="child"
          frameborder="0"
          :src="prevsrc"
        />
        <img class="childimg" alt="点击放大缩小图片" :src="prevsrc" v-else />
      </div>
    </el-dialog>
  </div>
</template>

<script>
//import { defaultOptions, renderAsync } from "docx-preview";
export default {
  name: 'FileReader',
  props: {
    file: {
      type: Object,
      default: function() {
        return {
          fileurl: '',
          dialogVisible: false,
          title: '',
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      fileType: '',
    }
  },
  // 这里是用来判断左边菜单栏是否打开
  computed: {
    prevsrc() {
      var type = this.file.fileurl.substring(
        this.file.fileurl.lastIndexOf('.') + 1
      )
      console.log(type)
      if (
        type === 'xlsx' ||
        type === 'xls' ||
        type === 'doc' ||
        type === 'docx' ||
        type === 'ppt' ||
        type === 'pptx'
      ) {
        this.fileType = 'notimg'
        return (
          'http://view.officeapps.live.com/op/view.aspx?src=' +
          encodeURIComponent(this.file.fileurl)
        )
      } else {
        if (type === 'pdf') {
          this.fileType = 'pdf'

          return this.file.fileurl
        }
        this.fileType = 'img'

        return this.file.fileurl
      }
    },
  },
  created() {},
  methods: {
    handleClose() {
      this.file.dialogVisible = false
    },
  },
}
</script>

<style scoped>
.child {
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: contain;
}
.childimg {
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0 auto;
  border: 0;
  object-fit: contain;
}
.child >>> .container p {
  position: relative;
}
.child >>> .container p img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.dialog-box >>> .el-dialog__headerbtn {
  font-size: 34px;
}
/* .analysis >>> .el-dialog {
  left: 119px;
} */
.analysis-dialog >>> .el-dialog__body {
  height: 80% !important;
}
.analysis-dialog >>> .el-dialog {
  top: 30px;
  height: 100%;
  width: 100%;
}
</style>
