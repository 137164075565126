<template>
  <div class="user">
    <!-- 修改用户 -->
    <el-dialog
      v-dialogDrag
      title="修改菜单名称"
      :visible.sync="dialogFormVisible"
      append-to-body
      @close="userClose(form)"
    >
      <div slot="header" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="edit" size="small" v-if="!state"
          >修 改</el-button
        >
        <el-button
          type="primary"
          @click="userAddSubmit(form)"
          v-if="state"
          size="small"
          >添 加</el-button
        >
      </div>
      <el-form :model="form">
        <el-form-item
          label="上级菜单"
          :label-width="formLabelWidth"
          v-if="!state"
        >
          <el-cascader
            ref="myCascader"
            :options="options"
            :props="optionProps"
            v-model="form.pid"
            @change="handleChange"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="添加人员" :label-width="formLabelWidth">
          <el-select v-model="form.uid" multiple placeholder="请选择">
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="有无页面" :label-width="formLabelWidth">
          <el-radio v-model="form.route" :label="1">有</el-radio>
          <el-radio v-model="form.route" :label="2">无</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="edit" size="small" v-if="!state"
          >修 改</el-button
        >
        <el-button
          type="primary"
          @click="userAddSubmit(form)"
          v-if="state"
          size="small"
          >添 加</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog
      v-dialogDrag
      title="删除菜单"
      :visible.sync="dialogVisible3"
      width="30%"
    >
      <span>{{ userdelinfo.name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="getDel" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input placeholder="请输入内容" v-model="inputSearch" size="small">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search(inputSearch)"
              size="small"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="allAdd()" size="small"
            >添加菜单</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-tree
        @node-drop="changeNode"
        style="width: 500px; margin-top: 10px"
        :data="treelist"
        default-expand-all
        node-key="id"
        ref="tree"
        highlight-current
        :props="defaultProps"
        :expand-on-click-node="false"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span :style="{ color: data.route == 1 ? 'red' : '' }">
            <i :class="data.icon"></i> {{ node.label }}</span
          >
          <span>
            <el-switch
              :width="30"
              style="margin-right: 20px"
              @change="changeState(node, data.id)"
              v-model="data.state"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              :disabled="showState ? true : false"
            >
            </el-switch>
            <el-button type="text" size="mini" @click="pAdd(node, data)">
              增加
            </el-button>
            <el-button type="text" size="mini" @click="handleEdit(node, data)">
              编辑
            </el-button>
            <el-button type="text" size="mini" @click="remove(node, data)">
              删除
            </el-button>
          </span>
        </span>
      </el-tree>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Breadcrumb from '@/components/breadcrumb.vue'
import {
  getEdit,
  getDel2,
  getDelState,
  getAdd,
  getOption,
  getList,
  getEmployee,
} from '@/api/department'
import { getNavList } from '@/api/home'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Breadcrumb },
  data() {
    //这里存放数据
    return {
      state: true,
      showState: false,
      //配置
      optionProps: {
        emitPath: false,
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      //上级列表
      options: [],
      options2: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
        pid: 0,
        route: 2,
        uid: [],
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible3: false,
      //控制弹窗--修改
      dialogFormVisible2: false,
      //控制弹窗--新增
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      treelist: [],
      defaultProps: {
        children: 'son',
        label: 'name',
      },
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeNode(before, after, inner, event) {
      console.log(before)
      console.log(after)
      console.log(inner)
      console.log(event)
    },
    async changeState(state, id) {
      this.showState = true
      console.log(this.showState)
      let info = await getDelState({ id: id })
      this.judge(info)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
      }
    },
    goTree() {
      this.$router.push('/department')
    },
    handleDragStart(node1) {
      console.log('drag start', node1)
    },
    handleChange(info) {
      console.log(this.options)
    },
    //父级增加
    allAdd(node, data) {
      this.state = true
      this.dialogFormVisible = true
    },
    pAdd(node, data) {
      this.state = true
      this.dialogFormVisible = true
      this.form.pid = data.id
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //编辑
    handleEdit(node, data) {
      this.state = false
      this.dialogFormVisible = true
      this.form = data
      //  console.log(data);
      //   console.log(this.form);
    },
    //删除
    remove(node, data) {
      this.getDel({ id: data.id, state: true })
    },
    //编辑
    async edit() {
      let info = await getEdit(this.form)
      this.judge(info)
    },
    //删除
    handleDelete(row, form) {
      this.dialogVisible3 = true
      this.userdelinfo = row
    },
    async getDel(info2) {
      let info = await getDel2(info2)
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
      this.dialogVisible3 = false
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    //添加事件--提交事件
    async userAddSubmit(e) {
      let info = await getAdd(this.form)
      this.judge(info)
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
        pid: 0,
        route: 2,
        uid: [],
      }
      console.log('close')
    },
    //目录接口
    async catalogue() {
      // 获取左侧目录
      let option = await getOption()
      let employee = await getEmployee()
      let list = await getList()
      this.options = option.data.data
      this.options2 = employee.data.data
      this.treelist = list.data.data
      this.showState = false
      this.getNavList()
    },
    async getNavList() {
      let info = await getNavList()
      info = info.data.data
      this.$store.commit('getNavigation', info)
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
