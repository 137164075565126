<!--  -->
<template>
    <div>
      <div class="status-point" style="background-color: #67c23a">0</div>
      <div class="status-point" style="background-color: #1b9aee">1</div>
      <div class="status-point" style="background-color: #fa8c15">0</div>
      <div class="status-point" style="background-color: red">0</div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.icon-lev {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  display: inline-block;
  padding: 0px 7px;
  line-height: 22px;
  font-size: 13px;
  color: #409eff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 3px;
}
.el-popover {
  background: black !important;
}
.popper__arrow {
  border: none;
}
.status-point {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px;
  background: #f00;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  text-align: center;
  line-height: 12px;
  color: white;
  font-size: 10px;
}
</style>