import { post } from '@/utils/request'
//任务中心
export function addTaskStair(data) {
    return post('admin/task/addTaskStair', data)
}
export function editTaskStair(data) {
    return post('admin/task/editTaskStair', data)
}
// 删除分类
export function delTaskStair(data) {
    return post('admin/task/delTaskStair', data)
}
// 分类排序
export function changeTaskStairOrder(data) {
    return post('admin/task/changeTaskStairOrder', data)
}
// 删除任务
export function delTask(data) {
    return post('admin/task/delTask', data)
}
export function Employee(data) {
    return post('admin/task/employee', data)
}
// 分类人员列表 2023/2/2
export function taskEmployee(data) {
    return post('admin/task/taskEmployee',data)
}

//标记所有信息为已读
export function read(data) {
    return post('admin/message/read',data)
}
// 删除所有已读信息
export function deleteAll(data) {
    return post('admin/message/delRead',data)
}
export function listTaskStair() {
    return post('admin/task/listTaskStair')
}
export function getMenuList() {
    return post('task/task/option')
}

//个人中心
export function taskmodelEdit(data) {
    return post('admin/taskmodel/edit', data)
}

export function taskmodelAddTaskr(data) {
    return post('admin/taskmodel/addTask', data)
}
//已完成
export function taskmodelAccomplish(data) {
    return post('admin/taskmodel/accomplish', data)
}
//开始任务
export function taskmodelConfirmTask(data) {
    return post('admin/taskmodel/confirmTask', data)
}
export function listTaskStairB(data) {
    return post("admin/task/listTaskStairB", data)
}

export function prioritySection(data) {
    return post('admin/priority/section', data)
}
export function infoTaskA(data) {
    return post('admin/task/infoTaskA', data)
}
export function checkTime(data) {
    return post('admin/Application/checkTime', data)
}
export function getAddReason(data) {
    return post('admin/taskreason/addReason', data)
}

export function getlistReason(data) {
    return post('admin/taskreason/listReason', data)
}
export function getEditReason(data) {
    return post('admin/taskreason/editReason', data)
}

export function getDelReason(data) {
    return post('admin/taskreason/delReason', data)
}
export function getTaskreason(data) {
    return post('admin/perform/score', data)
}

//审核接口

// 延期审核列表
export function getCheckList(data) {
    return post('admin/Application/checkList', data)
}
// 延期确定列表
export function getConsent(data) {
    return post('admin/Application/consent', data)
}
// 延期拒绝列表
export function getRefuse(data) {
    return post('admin/application/refuse', data)
}

// 任务里
//评分列表
export function getScore(data) {
    return post('admin/perform/score', data)
}

//同意 
export function getPConsent(data) {
    return post('admin/perform/consent', data)
}


// 复核列表
export function getListPerform(data) {
    return post('admin/perform/listPerform', data)
}
// 复核信息
export function getPerformInfo(data) {
    return post('admin/perform/performInfo', data)
}
// 复核通过
export function getAddPerform(data) {
    return post('admin/perform/addPerform', data)
}

//拒绝
export function getRejective(data) {
    return post('admin/perform/rejective', data)
}
// 移动任务到别的分类
export function updateTaskOrder(data) {
    return post('admin/task/updateTaskOrder', data)
}
// 垃圾箱列表
export function binList(data) {
    return post('admin/task/binTask', data)
}
// 清空垃圾箱
export function clearBin(data) {
    return post('admin/task/clearBin', data)
}
// 删除单个任务

export function deleteBinTask(data) {
    return post('admin/task/deleteBinTask', data)
}
// 恢复单个任务

export function recover(data) {
    return post('admin/task/recover', data)
}
// 跨项目移动任务

export function moveTask(data) {
    return post('admin/task/updateClass', data)
}