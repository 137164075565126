import { post } from '@/utils/request'

export function getEdit(data) {
    return post('task/department/editDepar', data)
}
export function getDel2(data) {
    return post('task/department/delDepart', data)
}
export function getDelState(data) {
    return post('task/department/editState', data)
}
export function getAdd(data) {
    return post('task/department/addDepar', data)
}
export function getOption(data) {
    return post('task/department/list', data)
}
export function getEmployee(data) {
    return post('task/department/employee', data)
}
export function getList() {
    return post('task/department/list')
}
// 获取分类


export function getClass() {
    return post('admin/task/getClass')
}