import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { getNavn } from './utils/utils.js'
import Base64 from './utils/base.js'
import dragscroll from './utils/dragscroll.js'
import '@/assets/font/font.css'

import uploader from 'vue-simple-uploader'
import ElementUI from 'element-ui'
import { myMessage } from './utils/resetMessage'
import * as ALLdirectives from './directives/imgError'
import './directives/dialogDrag'
import {
  Message,
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Avue from '@smallwei/avue';
//const Avue = window.AVUE
import '@smallwei/avue/lib/index.css';
import Breadcrumb from "@/components/breadcrumb.vue";
import {connectWebsocket,closeWebsocket} from '@/utils/websocket.js'
// import {globalWebSocket} from '@/utils/global.js'
// main.js 文件
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
Vue.use(Viewer);
import VueTouch from "vue-touch";
Vue.use(VueTouch, {name: "v-touch"});
import global from './utils/global.js'
Vue.prototype.global = global
// Vue.prototype.$globalWebSocket = globalWebSocket
Vue.prototype.$connectWebsocket = connectWebsocket
Vue.prototype.$closeWebsocket = closeWebsocket
// 引入NProgress
//import NProgress from 'nprogress'
// 引入nprogress样式文件
// import'nprogress/nprogress.css'
//引入
import socketPublic from '@/utils/socketVuex.js'
import moment from 'moment';
//挂载到原型
Vue.prototype.$moment = moment ;
//汉化，否则显示日期格式是国外的日期格式
moment.locale("zh-CN");
 
//挂载
Vue.prototype.$websocket = socketPublic

Vue.component('Breadcrumb', Breadcrumb)
Vue.use(Avue);
Vue.use(uploader)
Vue.use(ElementUI)
Vue.use(NProgress)
Vue.prototype.$message = myMessage

// 遍历得到的对象, 批量进行自定义指令的注册
for (const key in ALLdirectives) {
    // console.log(key, directives[key])
    Vue.directive(key, ALLdirectives[key])
  }
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.prototype.$getNavn = getNavn
Vue.prototype.$Base64 = Base64;
Vue.config.productionTip = false
// import 'default-passive-events'
import animate from 'animate.css'
Vue.directive('dragscroll', dragscroll);
import './directives/dialogDrag'
Vue.use(animate)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
