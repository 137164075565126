import { post } from '@/utils/request'

// websocket data
export function getWebsocketMessage(data) {
    return post('admin/socket/getRecord', data)
}
//获取消息
export function getWsMsgList(data) {
    return post('admin/message/list', data)
}


//获取未读消息总数
export function getClassMsgNum(data) {
    return post('/admin/socket/getClassMsgNum', data)
}

//获取未读消息列表
export function getUnreadList(data) {
    return post('/admin/message/noReadMsg', data)
}
//获取@我的消息列表
export function getRoundList(data) {
    return post('/admin/message/myMsg', data)
}