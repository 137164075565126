<!--  -->
<template>
  <div class="free-scroll-container">
    <div
      class="recordAll scroll-wrapper"
      ref="wrapper"
      @mouseenter="handleEnter"
      @mouseleave="handleLeave"
      id="recordAll"
    >
      <div class="record scroll-content" :style="`margin-left:${recordLeft}`">
        <span
          class="btn"
          :id="`id${index}`"
          v-for="(item, index) in tags"
          :key="index"
          @click="changeRecord(item, index)"
          :class="[
            item.router.query.id == (activerouter || 'uniqueIndex')
              ? 'btn-active'
              : '',
          ]"
          >{{ item.router.query.name }}
          <span
            class="record-x"
            @click.stop="close(item, index)"
            v-if="index != 0"
            >x</span
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import BScroll from '@better-scroll/core'

export default {
  props: {},
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      recordLeft: 0,
      rou: '',
      bs: '',
    }
  },
  //监听属性 类似于data概念
  computed: {
    changeRoute() {
      // console.log('route', this.$route)
      return this.$route
    },
    activerouter() {
      return this.$route.query.id
    },
    tags() {
      return this.$store.state.history
    },
  },
  //监控data中的数据变化
  watch: {
    changeRoute(info) {
      this.setHistory(info)
    },
  },
  //方法集合
  methods: {
    init() {
      this.bs = new BScroll(this.$refs.wrapper, {
        scrollX: true,
        probeType: 3, // listening scroll event
      })
      this.bs.on('scrollStart', () => {
        console.log('scrollStart-')
      })
      this.bs.on('scroll', ({ y }) => {
        console.log('scrolling-')
      })
      this.bs.on('scrollEnd', () => {
        console.log('scrollingEnd')
      })
    },
    handleEnter() {
      document.querySelector(`#recordAll`).style.width =
        document.querySelector(`#recordAll`).offsetWidth + 10
    },
    handleLeave() {
      document.querySelector(`#recordAll`).style.width =
        document.querySelector(`#recordAll`).offsetWidth - 10
    },
    close(item, index) {
      console.log(item)
      if (index == 0) {
        return
      }
      console.log(index)
      this.$store.commit('getroute', { index })

      if (this.rou.query.id == item.router.query.id) {
        this.$router.push(this.tags[index - 1].fullPath)
      }
      // let rou = this.$route.path;
      // let num = this.tags.findIndex(function (item) {
      //   return item.path == rou;
      // });
      // this.setRecord(num);
    },
    changeRecord(item, index) {
      if (this.$route.query.id == item.router.query.id) {
        return
      }

      console.log(item)
      console.log(this.$route)
      this.$router.push(item.fullPath)
    },
    setHistory(info) {
      this.rou = this.$route
      let arr = this.tags
      let that = this
      let state = arr.some(function (item, index) {
        // console.log(item.router.query.id);
        // console.log(info.query.id);
        if (item.router.query.id == info.query.id) {
          item.fullPath = info.fullPath
          return that.changeHis(item, info, index)
        }
      })
      if (!state) {
        this.$store.commit('getroute', info)
        // console.log("测试");
      } else {
        // console.log("已有了");
      }
      this.$nextTick(() => {
        let index = this.tags.findIndex(function (item) {
          return item.path == info.path
        })
        this.setRecord(index)
      })
    },
    //判断是否需要增加
    changeHis(item, info, index) {
      if (item.fullPath != info.fullPath) {
        this.$store.commit('getroute', { index2: index, info: info })
        // console.log("假的");
        return false
      } else {
        // console.log("真的");
        return true
      }
    },
    setRecord(index) {
      // console.log(index);
      let width = 120
      let widthAll = document.querySelector(`#recordAll`).offsetWidth
      if (widthAll === 0) {
        widthAll = 1000
      }
      // if (widthAll == 0) {
      //   widthAll = 1000;
      // }
      let itemWidth = index * width + width
      if (itemWidth > widthAll) {
        let recordLeft = itemWidth - widthAll
        this.recordLeft = -recordLeft + 'px'
      } else {
        this.recordLeft = '0'
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {
    this.setHistory(this.$route)
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.init()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {
    this.$store.commit('resetHistory')
  }, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.record {
  display: inline-flex;
  height: 30px;
  align-items: center;
  box-sizing: border-box;
  padding-top: 2px;
  white-space: nowrap;
  transition: all 0.3s;
}
.recordAll {
  overflow-y: hidden;
  overflow-x: scroll;
  width: 100%;
}
.recordAll::-webkit-scrollbar {
  height: 0;
}
.btn {
  padding: 4px 10px;
  font-size: 12px;
  color: #409eff;
  box-sizing: border-box;
  white-space: nowrap;
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid #409eff;
  /* border-right: 1px solid #eee;
    border-top: 1px solid #eee; */
  transition-duration: 0.3s;
  /* width: 100px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  border-radius: 15px;
  overflow: hidden;
  display: inline-block;
}
.btn-active {
  color: #fff !important;
  background: #409eff !important;
}
.record-x {
  display: none;
}
.btn:hover > .record-x {
  display: inline-block !important;
  margin-left: 6px;
  padding-bottom: 1px;
}
/* .btn:hover {
    width: 120px;
} */

/* .scroll-wrapper {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  width: 500px;
}
.scroll-content {
  display: inline-block;
  position: relative;
}
.free-scroll-container {
  position: relative;
} */
</style>
