!<!--  -->
<template>
  <div class="home">
    <div class="card">
      <el-dialog
      v-dialogDrag
        :lock-scroll="false"
        style="min-width: 1200px"
        width="90%"
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogFormVisible"
        @close="userClose()"
      >
        <el-form :model="form">
          <el-row>
            <el-col :span="6">
              <el-form-item label="任务模块" :label-width="formLabelWidth">
                <el-cascader
                  :options="options"
                  :props="optionProps"
                  v-model="form.hierarchy"
                  @change="handleChange"
                  filterable
                  clearable
                >
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务日期" :label-width="formLabelWidth">
                <el-date-picker
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="form.ht_add_time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="任务名称" :label-width="formLabelWidth">
                <el-input v-model="form.name" placeholder="任务名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="24"> </el-col>
          </el-row> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="edit">确 定</el-button>
        </div>
      </el-dialog>
      <el-row style="margin-bottom: 10px">
        <el-button type="primary" size="medium">任务通知占比</el-button>
      </el-row>
      <el-table
        :header-cell-style="{ background: '#FAFAFA' }"
        :data="tableData"
        style="width: 100%"
        height='300px'
      >
        <el-table-column prop="id" label="任务ID"> </el-table-column>
        <el-table-column prop="p_name" label="任务名称"> </el-table-column>
        <el-table-column prop="percentage" label="所占百分比">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              style="margin-right: 10px"
              size="mini"
              icon="el-icon-edit"
              @click="editJus(scope.row, form)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      title: "发布任务名称",
      dialogFormVisible: false,
      formLabelWidth: "110px",
      optionProps: {
        checkStrictly: false,
        value: "id",
        label: "name",
        children: "son",
      },
      form: {},
      options: [],
      tableData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleChange() {},
    edit() {},
    userClose() {},
    editJus() {},
    async getTableData() {
      let info = await this.$axios.post("admin/percentage/list");
      this.tableData = info.data.data;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.getTableData();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.card {
  width: 100%;
  background: #fff;
  margin-left: -8px;
  margin-right: -5px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}
#dialog {
  overflow-y: auto;
}
</style>