<template>
  <div class="menu-bug-wrap">
    <div v-for="menu in menuList" :key="menu.id">
      <!-- 如果当前有子菜单，则显示 el-submenu ，在el-subment 里调用 递归组件 -->
      <el-submenu
        v-if="menu.son || menu.route != 1"
        :index="'route' + menu.id"
        :key="menu.id"
        @mouseenter.stop="enter"
      >
        <template slot="title">
          <!-- <i :class="menu.icon" v-if="menu.icon"></i>
          <i class="el-icon-menu" v-else></i> -->
          <!-- <span class="icon-lev" @click.stop="changeColl">
            {{ menu.level }}</span
          > -->
          <span
            class="icon-lev"
            :style="{
              backgroundColor: setBgColor(menu.hierarchy),
              color: setTextColor(menu.hierarchy),
            }"
            @click.stop="changeColl"
          >
            {{ menu.hierarchy }}</span
          >
          <span :style="isCollapse ? 'visibility:hidden' : ''" class="name">
            {{ menu.name }}
            <!-- <NavPopover class="name-lev"></NavPopover> -->
          </span>
          <span
            class="red-bro"
            v-if="menu.no_read_num !== 0"
            style="color: #fff"
            >{{ menu.no_read_num }}
          </span>
          <!-- <span
            v-if="menu.no_read_num > 9 && menu.no_read_num < 100"
            class="red-bro1"
            style="color:#fff"
            >{{ menu.no_read_num }}
          </span>
          <span v-if="menu.no_read_num > 99" class="red-bro" style="color:#fff"
            >99+
          </span> -->
        </template>
        <!-- 调用自身  此处是重点-->
        <MenuTree :menuList="menu.son"></MenuTree>
      </el-submenu>
      <!-- 如果没有子菜单，则显示当前内容 -->
      <!-- taskmine?id=1&name=测试修改 -->
      <!-- <el-menu-item v-else :index="`taskmine?id=${menu.id}&name=${menu.name}`" :key="menu.id"> -->
      <el-menu-item
        v-else
        :index="`taskmine?id=${menu.id}&name=${menu.name}`"
        :key="menu.index"
      >
        <!-- <i :class="menu.icon" v-if="menu.icon"></i>
        <i class="el-icon-menu" v-else></i> -->
        <span
          class="icon-lev"
          :style="{
            backgroundColor: setBgColor(menu.hierarchy),
            color: setTextColor(menu.hierarchy),
          }"
          @click.stop="changeColl"
          >{{ menu.hierarchy }}</span
        >
        <!-- <span class="icon-lev" @click.stop="changeColl"> {{ menu.level }}</span> -->
        <span
          :style="isCollapse ? 'visibility:hidden' : ''"
          ref="name"
          class="name"
        >
          {{ menu.name }}
          <!-- <NavPopover class="name-lev"></NavPopover> -->
        </span>
        <span
          v-if="menu.no_read_num !== 0"
          class="red-bro"
          :class="[menu.no_read_num > 99 ? 'red-bro bigger' : 'red-bro']"
          >{{ menu.no_read_num }}
        </span>
        <!-- <span
          v-if="menu.no_read_num > 9 && menu.no_read_num < 100"
          class="red-bro1"
          style="color:#fff"
          >{{ menu.no_read_num }}
        </span> -->
      </el-menu-item>
    </div>
  </div>
</template>

<script>
import NavPopover from '@/components/NavPopover.vue'
export default {
  components: { NavPopover },
  name: 'MenuTree',
  props: {
    menuList: {
      required: false,
    },
    isCollapse: false,
  },
  computed: {
    setBgColor() {
      return function(val) {
        let bgColorStr = ''
        switch (val) {
          case 1:
            bgColorStr = '#124fa0'
            break
          case 2:
            bgColorStr = '#187dd1'
            break
          case 3:
            bgColorStr = '#2fc0d2'

            break
          case 4:
            bgColorStr = '#3766f9'
            break
          case 5:
            bgColorStr = '#fd9121'
            break
          case 6:
            bgColorStr = '#fb442a'
            break
          case 7:
            bgColorStr = '#e61e07'
            break
          case 8:
            bgColorStr = '#3facfe'
            break
          case 9:
            bgColorStr = '#0690ff'
            break
          case 10:
            bgColorStr = '#fdcfcf'
            break
          case 11:
            bgColorStr = '#fb7171'
            break
          case 12:
            bgColorStr = '#fa4647'
            break
          case 13:
            bgColorStr = '#f50504'
            break
          default:
            bgColorStr = '#000000'
        }
        return bgColorStr
      }
    },
    setTextColor() {
      return function(val) {
        let textColorStr = ''
        switch (val) {
          case 1:
            textColorStr = '#fff'
            break
          case 2:
            textColorStr = '#fff'
            break
          case 3:
            textColorStr = '#eee'
            break
          case 4:
            textColorStr = '#e6e6e6'
            break
          case 5:
            textColorStr = '#e3e3e3'
            break
          case 6:
            textColorStr = '#fff'
            break
          case 7:
            textColorStr = '#fff'
            break
          case 8:
            textColorStr = '#fff'
            break
          case 9:
            textColorStr = '#fff'
            break
          case 10:
            textColorStr = '#fdcfcf'
            break
          case 11:
            textColorStr = '#fb7171'
            break
          case 12:
            textColorStr = '#fa4647'
            break
          case 13:
            textColorStr = '#f50504'
            break
          default:
            textColorStr = '#000000'
        }
        return textColorStr
      }
    },
  },
  methods: {
    enter() {
      return
    },
    changeColl() {
      //   let info = !this.isCollapse
      //   this.$store.commit('changeColl', info)
    },
  },
}
</script>

<style scoped>
/* :style="isCollapse ? 'display:none' : ''" */
.menu-bug-wrap >>> .el-menu-item .is-active {
  background-color: #cacaca !important;
}
.el-menu--popup-right-start >>> {
  display: none !important;
}
.el-menu--collapse
  > .menu-bug-wrap
  >>> div
  > .el-submenu
  > .el-submenu__title
  > .el-submenu__icon-arrow {
  display: none;
}
.menu-bug-wrap {
}
/* .icon-lev {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  display: inline-block;
  padding: 0px 7px;
  line-height: 22px;
  font-size: 13px;
  color: #409eff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 3px;
} */
.icon-lev {
  /* background-color: #ecf5ff; */
  font-weight: 900;
  border-color: #d9ecff;
  display: inline-block;
  padding: 1px 8px;
  line-height: 22px;
  font-size: 13px;
  /* color: #409eff; */
  border-width: 1px;
  border-style: solid;
  border-radius: 14px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 3px;
}
.el-popover {
  background: black !important;
}
.popper__arrow {
  border: none;
}
.status-point {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px;
}
.name {
  position: relative;
  /* color: #a1a3a5; */
}
.name-lev {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.red-bro {
  /* background-color: red;
  border-radius: 50%;
  padding: 0px 6px 2px 5px;
  font-size: 10px;
  border: 1px solid #ccc; */
  background-color: #f56c6c;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
  /* top: 3px; */
  height: 22px;
  line-height: 23px;
  width: 22px;
  text-align: center;
  /* position: absolute; */
  font-size: 10px;
  /* border: 1px solid #ccc; */
}
.bigger {
  width: 25px !important;
  height: 25px !important;
  line-height: 26px !important;
}
.red-bro1 {
  background-color: #f56c6c;
  border-radius: 50%;
  padding: 0px 3px 1px 3px;
  font-size: 10px;
  border: 1px solid #ccc;
}
</style>
