<template>
  <div>
    <div id="chartPie" class="pie-wrap"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
// require("echarts/theme/macarons"); //引入主题,可以不引入，有默认的
export default {
  props: {
    info: Array,
  },
  data() {
    return {
      list: [],
      pieInfo: [],
    }
  },
  watch: {
    info() {
      this.list = []
      this.pieInfo = this.info
      if (this.pieInfo && this.pieInfo.length !== 0) {
        this.pieInfo.forEach((item) => {
          this.list.push({ value: item.percentage, name: item.name })
        })
      }

      this.drawPieChart()
      this.$forceUpdate()
    },
  },
  mounted() {
    this.pieInfo = this.info
    this.setInfo()
    this.$nextTick(() => {
      this.drawPieChart()
    })
  },
  methods: {
    setInfo() {
      // console.log(this.info)
      if (this.pieInfo && this.pieInfo.length !== 0) {
        this.pieInfo.forEach((item) => {
          this.list.push({ value: item.percentage, name: item.name })
        })
      }
    },
    drawPieChart() {
      this.$forceUpdate()
      this.chartPie = echarts.init(
        document.getElementById('chartPie'),
        'macarons'
      )
      this.chartPie.setOption({
        //显示在上面的文字
        tooltip: {
          trigger: 'item',
          // formatter: "{a}<br/>{b}: <br/>{c}({d}%)",
          // 其中 {a}指向name名称（访问来源）
        },

        series: [
          {
            label: {
              formatter: '{b}: {c}',
            },
            name: '任务占比',
            type: 'pie',
            radius: ['40%', '70%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#FFFFFF',
              borderWidth: 2,
            },
            data: this.list,
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: "rgba(0, 0, 0, 0.5)",
            //   },
            // },
          },
        ],
      })
    },
  },
}
</script>

<style scoped>
.pie-wrap {
  width: 500px;
  height: 150px;
}
</style>
