<!--  -->
<template>
  <div class="home">
    <transition
      :duration="{ enter: 5000, leave: 10000 }"
      enter-active-class="animated  bounceInDown"
    >
      <el-container class="home-info" v-show="flag">
        <el-aside
          class="aside"
          :style="
            isCollapse
              ? 'margin-right:10px;width:64px'
              : 'margin-right:10px;width:331px'
          "
        >
          <div
            v-if="compoName === 'Mobile' && projectFlag === true"
            class="nav-drawer1"
            @click="showPop()"
          >
            ||
          </div>
          <div class="logo">
            <div>
              <img v-show="!isCollapse" src="../../assets/elep.png" alt="" />
            </div>
            <!-- <span v-show="!isCollapse" style="margin-left: 10px"
              >浦江工业 | BUND Industries ™</span
            > -->
            <br />
            <span v-show="!isCollapse">
              <div style="color: #595959">OA任务中心</div>
              <!-- <div style="color: #fdfdfd">OA任务中心</div> -->
              <span style="padding: 5px"></span>
              <!-- <i
                v-for="(item) in infoSave"
                :key="item.id"
                :style="{ color: item.id == changeRou ? '#67C23A' : '' }"
                class="el-icon-message-solid route-icon1"
                @click="goSave(`taskmine?id=${item.id}&name=${item.name}`)"
              >
              </i> -->
            </span>
            <div class="ele">
              <img
                v-show="isCollapse"
                width="50"
                src="../../assets/elephant.png"
                alt=""
              />
            </div>
            <div style="margin-top: 10px">
              <span style="color: #595959" v-show="isCollapse">OA</span>
              <!-- <span style="color: #fdfdfd" v-show="isCollapse">OA</span> -->
            </div>
          </div>
          <div class="mobile-logo">
            <div class="left">
              <img src="../../assets/elep.png" width="150" />
              <!-- <div>任务中心</div> -->
            </div>
            <div class="right">
              <div>
                <el-dropdown trigger="click" @command="handleCommand">
                  <span class="el-dropdown-link">
                    <img :src="img" v-imgerror="Img" alt="" class="image1" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="del">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div>{{ username }}</div>
              <!-- <div @click="mobileAllwindows">
                <i class="el-icon-full-screen"></i>
              </div> -->
            </div>
          </div>
          <!-- 移动端首页 -->

          <v-touch @swipeleft="nowLeft()" @swiperight="nowRight()">
            <div
              v-if="compoName === 'Mobile' && projectFlag === true"
              class="mobile-task"
            >
              <transition enter-active-class="animated fadeInDownBig">
                <!-- <el-collapse-transition> -->
                <router-view ref="Childmain" :key="key"></router-view>
                <!-- </el-collapse-transition> -->
              </transition>
            </div>
          </v-touch>

          <div id="left-menu" v-if="compoName === 'Computer'" class="left-menu">
            <div @click="drawerClick()" class="nav-drawer">
              ||
            </div>
            <el-menu
              :collapse-transition="true"
              :collapse="isCollapse"
              :default-active="activerouter"
              class="el-menu-vertical-demo"
              background-color="#fdfdfd"
              text-color="#595959"
              active-text-color="#409eff"
              router
              :unique-opened="false"
              menu-trigger="click"
            >
              <NavMenu
                :menuList="navigationData"
                :isCollapse="isCollapse"
              ></NavMenu>
            </el-menu>
          </div>
          <!-- 导航 -->
          <div class="poper" v-if="compoName === 'Mobile'">
            <v-touch @swipeleft="nowLeft()" @swiperight="nowRight()">
              <van-popup
                position="left"
                v-model="drawerShow"
                :style="{ height: '100%' }"
              >
                <div v-if="projectFlag" class="left-menu">
                  <div @click="drawerClick()" class="nav-drawer">
                    ||
                  </div>
                  <el-menu
                    :collapse-transition="true"
                    :collapse="isCollapse"
                    :default-active="activerouter"
                    class="el-menu-vertical-demo"
                    background-color="#fdfdfd"
                    text-color="#595959"
                    active-text-color="#409eff"
                    router
                    :unique-opened="false"
                    menu-trigger="click"
                  >
                    <NavMenu
                      :menuList="navigationData"
                      :isCollapse="isCollapse"
                    ></NavMenu>
                  </el-menu>
                </div>
              </van-popup>
            </v-touch>
          </div>

          <!-- 消息详情 -->
          <div v-show="messageFlag" class="message-info">
            <mobile-info></mobile-info>
          </div>
          <!-- 我的 -->
          <div v-show="mineFlag" class="message-info">
            <van-cell-group inset>
              <van-cell title="用户名" :value="username" />
              <van-cell title="我的垃圾箱">
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                <template #right-icon>
                  <van-icon
                    name="delete-o"
                    class="delete-icon"
                    @click="openTranshcan"
                  />
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </el-aside>
        <div class="tab-bottom">
          <van-tabbar active-color="#187dd1" v-model="active">
            <van-tabbar-item name="project" icon="orders-o"
              >项目</van-tabbar-item
            >
            <van-tabbar-item name="message" :badge="count" icon="chat-o"
              >消息</van-tabbar-item
            >
            <van-tabbar-item name="mine" icon="user-o">我的</van-tabbar-item>
          </van-tabbar>
        </div>
        <!-- 内容 -->
        <el-container v-if="compoName === 'Computer'" class="home-info">
          <el-header
            class="header-title"
            :style="{ height: headerFlag ? '43px' : 0 }"
          >
            <div class="info-left">
              <div @click="changeIsCollapse">
                <i class="el-icon-s-unfold" v-if="isCollapse"></i>
                <i class="el-icon-s-fold" v-else></i>
              </div>
              <!-- <Breadnav></Breadnav> -->
              <!-- <Breadcrumb></Breadcrumb> -->

              <record-all></record-all>
            </div>
            <div class="info-right">
              <div></div>
              <div class="icon-click">
                <div>
                  <div slot="reference" @click="taskList">
                    <i class="el-icon-bell"></i>
                    <div class="red-bro1" v-if="count < 10">
                      <div class="num">{{ count }}</div>
                    </div>
                    <div class="red-bro2" v-if="count <= 99 && count >= 10">
                      <div class="num">{{ count }}</div>
                    </div>
                    <div class="red-bro3" v-if="count > 99">{{ count }}</div>
                  </div>
                </div>
                <div @click="allwindows">
                  <i class="el-icon-full-screen"></i>
                </div>
                <div @click="openTranshcan()" class="transhcan">
                  <img
                    style="margin-bottom: 1px"
                    src="../../assets/trash2.png"
                    width="24"
                    alt=""
                  />
                </div>
                <div
                  class="welcome"
                  style="
                    color: #666;
                    font-size: 16px;
                    margin-right: 0;
                    white-space: nowrap;
                    font-family:'PingFangRegular';
                  "
                >
                  欢迎您 , {{ username }}
                </div>
              </div>
              <div>
                <div class="title-info">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      <img :src="img" v-imgerror="Img" alt="" class="image" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="del"
                        >退出登录</el-dropdown-item
                      >
                      <!-- <el-dropdown-item command="mine"
                        >个人中心</el-dropdown-item
                      >
                      <el-dropdown-item command="all"
                        >更多设置</el-dropdown-item
                      >

                      <el-dropdown-item
                        :command="item"
                        v-for="(item, idx) in project"
                        :key="idx"
                        >{{ item.name }}</el-dropdown-item
                      > -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </el-header>

          <el-main class="info-main">
            <transition enter-active-class="animated fadeInDownBig">
              <!-- <el-collapse-transition> -->
              <router-view ref="Childmain" :key="key"></router-view>
              <!-- </el-collapse-transition> -->
            </transition>
          </el-main>
        </el-container>
      </el-container>
    </transition>

    <Transhcan
      @dialogClose="dialogClose"
      :trashcanVisible="trashcanVisible"
      ref="child"
    ></Transhcan>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import NavMenu from '@/components/NavMenu'
import Img from '@/assets/defaultStaffPhoto.png'
import YImg from '@/assets/ye.png'
import Transhcan from '@/components/Transhcan.vue'
import MobileInfo from '@/components/MobileInfo/MobileInfo.vue'
import Breadnav from '@/components/Breadnav'
import Breadcrumb from '@/components/breadcrumb'
import RecordAll from '@/components/RecordAll'
import { getNavList, getAddSave, getInfoSave } from '@/api/home'
import { getClassMsgNum } from '@/api/ws'

import Socket from '../../utils/websocket'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    NavMenu,
    Breadnav,
    RecordAll,
    MobileInfo,
    Breadcrumb,
    Transhcan,
  },
  data() {
    //这里存放数据
    return {
      infoSave: [
        { id: '1', name: '测试1' },
        { id: '2', name: '测试2' },
        { id: '3', name: '测试3' },
      ],
      drawerShow: false,
      projectFlag: false,
      messageFlag: false,
      mineFlag: false,
      active: 'project',
      username: sessionStorage.getItem('name'),
      Img: Img,
      YImg: YImg,
      timer: '',
      trashcanVisible: false,
      drawer: false,
      headerFlag: true,
      flag: false,
      token: window.sessionStorage.getItem('token'),
      img: window.sessionStorage.getItem('img'),
      taskTable: [],
      fullscreen: false,
      activeName: 'first',
      color: '#545C64',
      color2: '#F8F8FF',
      name: window.sessionStorage.getItem('name'),
      isicon: [],
      opened: true,
      //   project: [
      //     { name: '电商系统', url: 'http://sysapp.campgrounds.cn' },
      //     { name: 'Teambition', url: 'https://www.teambition.com/todo' },
      //   ],
      websock: null,
      instantMsg: [],
      allMsg: '',
      compoName: '',
    }
  },
  created() {
    // this.mysocket.name()
    // console.log(Socket)
    this.getCount()
    // if (this.isChrome) {
    //   alert('是chrome')
    // } else {
    //   alert('不是chrome')
    // }
    //方法二、
    // 获取当前登录的浏览器

    var userAgent = navigator.userAgent //取得浏览器的userAgent字符串
    // console.log("loginuserAgent:", userAgent)
    //判断是否Opera浏览器
  },

  //监听属性 类似于data概念
  computed: {
    pageWidth() {
      return window.innerWidth
    },
    isChrome() {
      //   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      alert(navigator.userAgent)
      return navigator.userAgent.indexOf('Chrome') > -1 ? true : false
    },
    navi() {
      if (navigator.userAgent.indexOf('Opera') > -1) {
        return 'Opera'
      }

      //判断是否Edge浏览器
      if (navigator.userAgent.indexOf('Edg') > -1) {
        return 'Edge'
      }

      //判断是否Firefox浏览器
      if (navigator.userAgent.indexOf('Firefox') > -1) {
        return 'firefox'
      }

      //判断是否Chrome浏览器
      if (navigator.userAgent.indexOf('Chrome') > -1) {
        return 'Chrome'
      }

      //判断是否Safari浏览器
      if (navigator.userAgent.indexOf('Safari') > -1) {
        return 'Safari'
      }

      //判断是否IE浏览器
      if (
        navigator.userAgent.indexOf('compatible') > -1 &&
        navigator.userAgent.indexOf('MSIE') > -1 &&
        !isOpera
      ) {
        return 'IE'
      }
      if (navigator.userAgent.indexOf('Trident') > -1) {
        return 'IE'
      }
    },
    key() {
      return this.$route.fullPath
    },
    changeRou() {
      return this.$route.query.id
    },
    activerouter() {
      if (
        this.$route.query.id == 'undefined' ||
        this.$route.query.name == 'undefined'
      ) {
        console.log('undefined出现了')
        return '/index'
      } else {
        let defaultMenu = `taskmine?id=${this.$route.query.id}&name=${this.$route.query.name}`

        localStorage.setItem('activerouter', defaultMenu)
        const expire = 1000 * 60 * 60 * 24 * 7
        setTimeout(() => {
          localStorage.setItem('activerouter', '')
        }, expire)
        return localStorage.getItem('activerouter')
      }
    },
    navigationData() {
      return this.$store.state.navigationData
    },
    isCollapse() {
      return this.$store.state.isCollapse
    },
    count() {
      return (
        this.$store.state.msgCount || window.sessionStorage.getItem('msgCount')
      )
    },
  },
  //监控data中的数据变化
  watch: {
    pageWidth: {
      handler(newVal) {
        if (newVal > 768) {
          this.compoName = 'Computer'
        } else {
          this.compoName = 'Mobile'
        }
      },
      immediate: true,
    },

    active: {
      handler(newval, oldval) {
        if (newval === 'project') {
          this.projectFlag = true
          this.messageFlag = false
          this.mineFlag = false
        } else if (newval === 'message') {
          this.projectFlag = false
          this.messageFlag = true
          this.mineFlag = false
        } else if (newval === 'mine') {
          this.projectFlag = false
          this.messageFlag = false
          this.mineFlag = true
        }
      },
      immediate: true,
    },
  },
  //方法集合
  methods: {
    nowLeft() {
      if (this.drawerShow === true) {
        this.drawerShow = false
      }
    },
    nowRight() {
      if (this.drawerShow === false) {
        this.drawerShow = true
      }
    },
    showPop() {
      this.drawerShow = true
    },
    drawerClick() {
      //console.log('aaa')
      this.drawerShow = false
    },
    async getCount() {
      if (this.$route.path !== '/index') {
        // 如果访问的是消息首页就不请求
        const res = await getClassMsgNum()
        this.allMsg = res.data.data
        this.$store.commit('setMsgCount', this.allMsg)
      }
    },
    goHeader() {
      this.headerFlag = !this.headerFlag
    },
    async taskList() {
      //let info = await this.$axios.post('admin/inform/list')
      //   console.log(info);
      //this.taskTable = info.data.data
      this.$router.push('/index')
    },
    //切换菜单栏
    changeIsCollapse() {
      console.log(this.isCollapse)
      let info = !this.isCollapse
      this.$store.commit('changeColl', info)
    },
    goMine() {
      this.$router.push('/mine')
    },
    gotest() {
      if (this.$route.path == '/taskall') {
        return
      }
      this.$router.push('/taskall')
    },
    // 打开垃圾桶
    openTranshcan() {
      //this.$message.info('垃圾箱开发中~~')
      this.$refs.child.getList()
      this.trashcanVisible = true
    },
    dialogClose() {
      this.trashcanVisible = false
    },
    mobileAllwindows() {
      let el = document.documentElement
      // 不同浏览器兼容方法调用
      if (el.requestFullScreen) {
        el.requestFullScreen()
      } else if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen()
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen()
      } else if (el.msRequestFullScreen) {
        el.msRequestFullScreen()
      }
    },
    //全屏事件
    allwindows() {
      let element = document.documentElement
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    },
    goIndex(url) {
      if (this.$route.fullPath == url) {
        return
      }
      this.$router.push(url)
    },
    goSave(url) {
      console.log(url)
      let fullPath = `taskmine?id=${this.$route.query.id}&name=${this.$route.query.name}`
      if (fullPath == url) {
        return
      }
      this.$router.push(url)
    },
    goRoute(url) {
      console.log(url)
      window.open(url, '_blank')
    },
    handleCommand(command) {
      console.log(command)
      if (command == 'del') {
        this.del()
      } else if (command == 'mine') {
        this.$router.push('/mine')
      } else if (command == 'all') {
        this.$router.push('/all')
      } else {
        console.log(command)
        this.goRoute(command.url)
      }
    },
    add() {
      let data = [
        {
          path: '*',
          component: () => import('@/views/Home/404.vue'),
        },
      ]
      this.$router.addRoutes(data)
    },
    //退出登录
    del() {
      window.sessionStorage.clear()
      this.$message({
        message: '已退出登录',
        type: 'warning',
        duration: 1000,
      })
      this.$router.push('/login')
    },
    // 获取左侧导航菜单列表
    async getNavList() {
      let info = await getNavList()
      info = info.data.data
      this.$store.commit('getNavigation', info)
      //  / console.log(info)
      let titleids = []
      if (Array.isArray(info)) {
        info?.forEach((item) => {
          titleids.push(item.id)
          if (item.son) {
            item.son.forEach((i) => {
              titleids.push(i.id)
            })
          }
        })
      } else {
        for (let key in info) {
          titleids.push(info[key].id)
          if (info[key].son) {
            for (let k in info[key].son) {
              titleids.push(info[key].son[k].id)
            }
          }
        }
      }

      // console.log(titleids)
      window.sessionStorage.setItem('navi_ids', titleids)
    },
    async setSave(type) {
      let form = {
        task_id: this.$route.query.id,
        order: type,
      }

      let info = await getAddSave(form)
      this.judge(info)
    },
    async judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        let info2 = await getInfoSave()
        this.infoSave = info2.data.data
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
      }
    },
    async setKey() {
      let info = await getInfoSave()
      this.infoSave = info.data.data
      let that = this
      document.onkeydown = function(e) {
        if (e.key == '`' && e.ctrlKey == true) {
          that.changeIsCollapse()
        }
        if (e.keyCode == '49' && e.ctrlKey == true && e.shiftKey == true) {
          that.setSave(1)
        }
        if (e.keyCode == '50' && e.ctrlKey == true && e.shiftKey == true) {
          that.setSave(2)
        }
        if (e.keyCode == '51' && e.ctrlKey == true && e.shiftKey == true) {
          that.setSave(3)
        }
        that.infoSave.forEach((item, index) => {
          if (e.key == index + 1 && e.altKey == true) {
            that.goSave(`taskmine?id=${item.id}&name=${item.name}`)
          }
        })
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）

  beforeMount() {
    this.setKey()
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    //this.$globalWebSocket.ws.onmessage = this.getNavList()
    this.getNavList()

    this.flag = true
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {
    // this.websock.close() //离开路由之后断开websocket连接
  }, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.home {
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  overflow: hidden;
}
.el-dropdown-link {
  cursor: pointer;
  color: #606266;
}
.nav-drawer {
  display: none;
}
.nav-drawer1 {
  display: none;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.home-info {
  height: 100%;
  width: 100%;
}
.name {
  font-size: 14px;
}
.header-title {
  /* width: 100%; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #eee;
  transition: all 0.3s;
  border-radius: 12px;
  margin: 15px 15px 10px 0px;
  box-sizing: border-box;
}
.title-info {
  width: 100%;
  line-height: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  border-radius: 10px;
}
.title {
  font-weight: 400;
  height: 100%;
  width: 200px;
  text-align: center;
  background: #cccccc;
  position: absolute;
  top: -20;
  left: 0;
  z-index: 999;
}
.aside {
  /* overflow-x: hidden; */
  overflow: hidden;
  /* background-color: #20222a; */
  background-color: #fdfdfd;
  transition: all 0.3s;
}
.mobile-logo {
  display: none;
}
.tab-bottom >>> .van-tabbar--fixed {
  display: none;
}
.message-info {
  display: none;
}
.home >>> .van-popup--left {
  display: none;
}
.mobile-task {
  display: none;
}
@media screen and (max-width: 768px) {
  .el-icon-full-screen {
    font-size: 14px !important;
    /* font-weight: 700; */
    color: #595959;
  }
  .tab-bottom >>> .van-info {
    background-color: #f56c6c !important;
  }
  .tab-bottom >>> .van-tabbar--fixed {
    display: flex;
  }
  .aside {
    background-color: #f7f8fa;
  }
  .message-info {
    display: block;
  }
  .message-info >>> .van-cell-group--inset {
    margin: 20px 16px;
  }
  .aside {
    width: 100vw !important;
  }
  .logo {
    display: none;
  }
  .mobile-logo {
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    border-bottom: 1px solid #eee;
    color: #646566;
    font-size: 14px;
  }
  .mobile-logo .left {
    display: flex;
    align-items: center;
  }
  .mobile-logo .right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-drawer {
    display: block;
    height: 50px;
    width: 12px;
    border: 1px solid #eee;
    color: #ccc;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    line-height: 45px;
    text-align: center;
    border-radius: 5px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-right-color: transparent;
    background-color: #d4eafd;
    opacity: 0.5;
    user-select: none;
    cursor: pointer;
  }
  .nav-drawer1 {
    display: block;
    height: 50px;
    width: 12px;
    border: 1px solid #eee;
    color: #ccc;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    line-height: 45px;
    text-align: center;
    border-radius: 5px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-right-color: transparent;
    background-color: #5a81b7;
    opacity: 0.6;
    user-select: none;
    cursor: pointer;
  }
  .home >>> .van-popup--left {
    display: block;
    width: 40vh;
  }
  #left-menu {
    display: none;
  }
  .poper .left-menu {
    height: 100%;
  }
  .mobile-task {
    display: block;
    width: 100%;
    height: calc(100% - 130px);
    padding-left: 12px;
  }
  .mobile-task .home-index {
    min-height: calc(100% - 368px) !important;
  }
  .mobile-task >>> .task-info {
    min-height: calc(100% - 368px) !important;
    /* height: 100%; */
  }
  .mobile-task >>> .task-card-sc {
    max-height: calc(100vh - 337px) !important;
  }
  .mobile-task >>> .info-card1 {
    /* height: calc(100vh - 123px) !important; */
    /* height: calc(100vh - 210px) !important; */

    /* 兼容 苹果手机 */
    height: calc(
      100vh - 240px - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    ) !important;
  }
  .mobile-task .task-all >>> .el-loading-mask {
    width: 100vw !important;
  }
  .mobile-task >>> .task-info-a {
    height: 100%;
  }
  .mobile-task .info-card1 >>> .el-button {
    margin-top: 4px !important;
  }
}
.el-menu {
  border-right-width: 0;
}

.el-menu-vertical-demo {
  position: relative;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  /* width: 324px; */
  position: relative;
}
.aside-title {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
}
.el-main {
  padding: 0;
}
.left-menu >>> .el-menu-item.is-active {
  /* background-color: #e8e8e9 !important; */
}
.info-main {
  /* padding: 0px 0px 10px 10px; */
  position: relative;
  padding: 0 14px 0 0;
  /* border-radius: 15px; */
}

.image {
  background-color: #999;
  margin: -12px 8px 0 5px;
  padding: 2px;
  width: 65px;
  height: 65px;
  box-shadow: 0px 0px 5px 2px #999;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee;
  vertical-align: middle;
  position: relative;
  z-index: 999;
  top: 10px;
  background: #f2f2f2;
}
.image1 {
  background-color: #999;
  margin: -12px 8px 0 5px;
  padding: 2px;
  width: 35px;
  height: 35px;
  box-shadow: 0px 0px 4px 2px #124fa0;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee;

  position: relative;
  z-index: 999;
  top: 10px;
  background: #f2f2f2;
}
.info-right {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-right > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.icon-click > div {
  height: 100%;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.info-left {
  width: 70%;
  display: flex;
  align-items: center;
}
.info-left > div {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
}

/* .logo {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background-color: #20222a;
  font-size: 20px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  color: hsla(0, 0%, 100%, 0.8);
  text-align: center;
  border-bottom: 2px solid #1f2129;
} */
.logo {
  width: 100%;
  height: 140px;
  line-height: 22px;
  /* padding: 20px 0; */
  padding-top: 12px;
  /* background-color: #20222a; */
  background-color: #fdfdfd;
  font-size: 23px;
  font-weight: 700;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%); */
  color: #fff;
  text-align: center;
  border-bottom: 1px solid #ccc;
}
.logo img {
  width: 285px;
  border-radius: 5px;
}
.ele div {
  margin-bottom: 20px;
}
.ele img {
  width: 50px;
}
.left-menu {
  position: relative;
  height: calc(100% - 122px);
  overflow-x: hidden;
  overflow-y: scroll;
  font-weight: 400;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar-track {
  display: none !important;
}
.el-icon-s-unfold,
.el-icon-s-fold {
  font-size: 30px;
  color: #595959;
  cursor: pointer;
  margin-left: -10px;
  margin-top: 2px;
}
.goroute {
  padding-right: 30px;
  cursor: pointer;
}
.el-icon-bell {
  cursor: pointer;
  font-size: 20px;
  margin-top: 1px;
  color: #595959;
  font-weight: 700;
  margin-right: 3px;
  margin-top: 1px;
}
.el-icon-full-screen {
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  color: #595959;
}

.icon-click > div {
  position: relative;
}
.red-bro1 {
  position: absolute;
  transform: scale(0.9);
  line-height: 15px;
  text-align: center;
  top: -11px;
  left: 10px;
  /* right: -23px; */
  padding: 3px 8px;
  background-color: #f56c6c;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  /* padding: 9px; */
  text-align: center;
  white-space: nowrap;
  border: 3px solid #fff;
}
.red-bro2 {
  position: absolute;
  transform: scale(0.9);
  line-height: 15px;
  text-align: center;
  top: -11px;
  left: 10px;
  font-weight: 700;
  /* right: -23px; */
  padding: 3px;
  background-color: #f56c6c;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  /* padding: 9px; */
  text-align: center;
  white-space: nowrap;
  border: 3px solid #fff;
}
.red-bro3 {
  position: absolute;
  transform: scale(0.9);
  line-height: 15px;
  text-align: center;
  top: -11px;
  left: 10px;
  font-weight: 700;
  /* right: -23px; */
  padding: 4px 1px 3px 1px;
  background-color: #f56c6c;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  /* padding: 9px; */
  text-align: center;
  white-space: nowrap;
  border: 3px solid #fff;
}
.red-bro .num {
  font-size: 12px;
  color: #fff;
  position: absolute;
  top: 2px;
  left: 5px;
}
.task {
  position: fixed;
  top: 50%;
  right: -2px;
  z-index: 999;
  color: #fff;
  background-color: #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #409eff;
  box-sizing: border-box;
  outline: 0;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}
.task2 {
  transition: all 0.3s;
  height: 34px;
}
.first-info {
  height: 200px;
  overflow: auto;
}
.first {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
}
.first-title {
  width: 300px;
  display: flex;
  align-items: center;
}
.first-name {
  flex: 1;
  display: flex;
  align-items: center;
}
.el-card >>> {
  border-radius: 15px !important;
}
.route-icon1 {
  color: #f56c6c;
  cursor: pointer;
  position: relative;
  font-size: 23px !important;
}
.route-number {
  position: absolute;
  top: 4px;
  left: 7px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
}
.delete-icon {
  font-size: 14px;
  line-height: inherit;
}
</style>
