import Vue from 'vue'
import store from '../store/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
//主页
import Home from '../views/Home/Home.vue'
//登录
import Login from '../views/Login/Login.vue'
//首页
import Index from '../views/Home/Index/index.vue'
//目录管理
import Cataloglist from '../views/Home/Catalog/department/cataloglist.vue'
import NotFound from '../views/Home/404.vue'
import { log } from 'mathjs/lib/cjs/entry/pureFunctionsAny.generated'
//import error404 from '../views/Home/404.vue'
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
})
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    redirect: '/index',
    component: Home,
    children: [
      {
        path: '/index',
        component: Index,
      },
    ],
  },
  //目录管理
  {
    path: '/list',
    redirect: '/department',
    component: Home,
    children: [
      {
        //目录管理
        path: '/department',
        component: Cataloglist,
      },
      {
        path: '/role',
        component: Index,
        component: () => import('../views/Home/Catalog/role/role.vue'),
      },
    ],
  },
  //任务模块
  {
    path: '/task',
    redirect: '/taskall',
    component: Home,
    children: [
      {
        path: '/taskmine',
        component: () => import('@/views/Home/task/taskmine.vue'),
      },
      {
        path: '/taskall',
        component: () => import('@/views/Home/task/taskall.vue'),
      },
    ],
  },

  {
    //登录
    path: '/login',
    name: 'login',
    component: Login,
  },
  // 404页面
  {
    path: '/notfound',
    name: 'not-found',
    component: NotFound,
  },
  //   {
  //     path: "/:catchAll(.*)",
  //     redirect:'/notfound',

  //   }
]
const router = new VueRouter({
  routes,
})
router.beforeEach((to, from, next) => {
  NProgress.start()
  //alert(store.getters.navitype);

  //  if(store.getters.navitype!=='Chrome'&&store.getters.navitype!=='Safari'){
  //     // console.log('not chrome');
  //     // console.log(to);
  //     // console.log(from);
  //    if(to.path==='/notfound'){
  //     next()

  //    }else {
  //     next(
  //     '/notfound'
  //     )
  //    }
  //    NProgress.done();
  // }

  let navi_ids = window.sessionStorage.getItem('navi_ids')
  if (navi_ids) {
    navi_ids = navi_ids.split(',')
  }
  console.log(navi_ids)
  console.log(to)
  // 判断是否有权限 没有权限进行拦截
  if (navi_ids && to.query.id && !navi_ids.includes(to.query.id)) {
    Message({
      type: 'error',
      message: '您没有访问权限，请联系管理员',
    })
    return
  }
  if (
    to.path === 'undefined' ||
    JSON.stringify(to.query) === '{}' ||
    to.path === '/notfound'
  ) {
    return next()
  } else {
    store.commit('getroute', { path: to.path, query: to.query })
  }
  // console.log(store.state.history);

  let token = sessionStorage.getItem('token')
  if (to.path === '/login') {
    // let info = []
    // router.addRoutes(info)
    return next()
  }
  if (!token && !to.query.token && to.path !== '/login') {
    next('/login')
  }
  if (to.query.token) {
    console.log(to.query.token)
    window.sessionStorage.setItem('token', to.query.token)
  }
  next()
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
export default router
