<template>
  <div class="info-card">
    <div class="info-card-inner">
      <div class="info-panel left-info">
        <div class="tabs">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              style="height: 90%; overflow-y: auto; overflow-x: hidden"
              name="first"
              id="pane-first1"
            >
              <div @click="nextMsg()" class="panel-title" slot="label">
                <span>全部通知</span>
                <!-- <div class="count" v-if="allMsg.count > 0">
                  {{ allMsg.count }}
                </div> -->
                <div
                  class="red-bro1"
                  v-if="allmsgcount < 10 && allmsgcount !== 0"
                >
                  <div class="num">{{ allmsgcount }}</div>
                </div>
                <div
                  class="red-bro2"
                  v-if="allmsgcount <= 99 && allmsgcount >= 10"
                >
                  <div class="num">{{ allmsgcount }}</div>
                </div>
                <div class="red-bro3" v-if="allmsgcount > 99">
                  {{ allmsgcount }}
                </div>
              </div>
              <div
                class="note-list"
                v-if="allMsg && allMsg.length !== 0"
                v-loading="loading"
              >
                <div
                  :class="[
                    isBoxActive == index ? 'note-box chosen' : 'note-box',
                    item.no_read_num === 0 ? '' : 'hasMsg',
                  ]"
                  v-for="(item, index) in allMsg"
                  :key="item.id"
                  @click="getTaskDetail(item.id, index, true)"
                >
                  <div class="close">
                    <i class="el-icon-close" @click="deleteMsg(item.id)"></i>
                    <div class="info-tag" v-if="item.no_read_num !== 0">
                      {{ item.no_read_num }}
                    </div>
                  </div>

                  <div :class="['project', { readed: item.no_read_num === 0 }]">
                    <span><i class="el-icon-menu"></i> </span>
                    {{ item.class }}
                  </div>
                  <div class="note-title">
                    <i class="el-icon-date"></i>
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="note-info">
                    <img
                      width="24"
                      v-if="item.info[0].portrait !== ''"
                      height="24"
                      :src="item.info[0].portrait"
                      alt=""
                    />
                    <img
                      v-else
                      width="24"
                      height="24"
                      src="../../assets/defaultStaffPhoto.png"
                      alt=""
                    />
                    <div class="task-info">
                      <div
                        :class="['news', { readed: item.no_read_num === 0 }]"
                      >
                        {{ item.info[0].uname }} {{ item.info[0].content }}
                      </div>
                      <div
                        :class="['time', { readed: item.no_read_num === 0 }]"
                      >
                        {{ item.info[0].time }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="no-msg">
                <div>
                  <img
                    width="74"
                    height="74"
                    src="../../assets/nomsg.png"
                    alt=""
                  />
                  <div style="text-align: center">暂无通知</div>
                </div>
              </div>
              <div class="btm" v-show="showBottom">
                没有更多数据啦~
              </div>
            </el-tab-pane>
            <el-tab-pane
              style="height: 90%; overflow-y: auto; overflow-x: hidden"
              name="second"
              id="pane-sec1"
            >
              <div class="panel-title" slot="label">
                <span>未读</span>

                <div
                  class="red-bro1"
                  v-if="allmsgcount < 10 && allmsgcount !== 0"
                >
                  <div class="num">{{ allmsgcount }}</div>
                </div>
                <div
                  class="red-bro2"
                  v-if="allmsgcount <= 99 && allmsgcount >= 10"
                >
                  <div class="num">{{ allmsgcount }}</div>
                </div>
                <div class="red-bro3" v-if="allmsgcount > 99">
                  {{ allmsgcount }}
                </div>
                <!-- <div class="count" v-if="unreadMsg.count > 0">
                  {{ unreadMsg.count }}
                </div> -->
              </div>
              <div
                v-loading="loading"
                v-if="unreadMsg && unreadMsg.length !== 0"
                class="note-list"
              >
                <div
                  :class="[
                    isBoxActive == index ? 'note-box chosen' : 'note-box',
                  ]"
                  v-for="(item, index) in unreadMsg"
                  @click="getTaskDetail(item.id, index, true)"
                  :key="item.id"
                >
                  <div class="close">
                    <i class="el-icon-close" @click="deleteMsg(item.id)"></i>
                    <div class="info-tag" v-if="item.no_read_num !== 0">
                      {{ item.no_read_num }}
                    </div>
                  </div>
                  <div :class="['project', { readed: item.no_read_num === 0 }]">
                    <span><i class="el-icon-menu"></i> </span>
                    {{ item.class }}
                  </div>
                  <div class="note-title">
                    <i class="el-icon-date"></i>
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="note-info">
                    <img
                      width="24"
                      v-if="item.info[0].portrait !== ''"
                      height="24"
                      :src="item.info[0].portrait"
                      alt=""
                    />
                    <img
                      v-else
                      width="24"
                      height="24"
                      src="../../assets/defaultStaffPhoto.png"
                      alt=""
                    />
                    <div class="task-info">
                      <div
                        :class="['news', { readed: item.no_read_num === 0 }]"
                      >
                        {{ item.info[0].uname }} {{ item.info[0].content }}
                      </div>
                      <div
                        :class="['time', { readed: item.no_read_num === 0 }]"
                      >
                        {{ item.info[0].time }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="no-msg">
                <div>
                  <img
                    width="74"
                    height="74"
                    src="../../assets/nomsg.png"
                    alt=""
                  />
                  <div style="text-align: center">暂无通知</div>
                </div>
              </div>
              <div class="btm" v-show="showBottom1">
                没有更多数据啦~
              </div>
            </el-tab-pane>
            <el-tab-pane
              style="height: 90%; overflow-y: auto; overflow-x: hidden"
              name="third"
              id="pane-third1"
            >
              <div class="panel-title" slot="label">
                <span>@我的</span>
                <!-- <div class="count" v-if="userData.count && userData.count > 0">
                  {{ userData.count }}
                </div> -->

                <div
                  class="red-bro1"
                  v-if="userDatacount < 10 && userDatacount !== 0"
                >
                  <div class="num">{{ userDatacount }}</div>
                </div>
                <div
                  class="red-bro2"
                  v-if="userDatacount <= 99 && userDatacount >= 10"
                >
                  <div class="num">{{ userDatacount }}</div>
                </div>
                <div class="red-bro3" v-if="userDatacount > 99">
                  {{ userDatacount }}
                </div>
              </div>
              <div
                class="note-list"
                v-if="userData && userData.length !== 0"
                v-loading="loading"
              >
                <div
                  :class="[
                    isBoxActive == index ? 'note-box chosen' : 'note-box',
                  ]"
                  v-for="(item, index) in userData"
                  @click="getTaskDetail(item.id, index, true)"
                  :key="item.id"
                >
                  <div class="close">
                    <i class="el-icon-close" @click="deleteMsg(item.id)"></i>
                    <div class="info-tag" v-if="item.no_read_num !== 0">
                      {{ item.no_read_num }}
                    </div>
                  </div>
                  <div :class="['project', { readed: item.no_read_num === 0 }]">
                    <span><i class="el-icon-menu"></i> </span>
                    {{ item.class }}
                  </div>
                  <div class="note-title">
                    <i class="el-icon-date"></i>
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="note-info">
                    <img
                      width="24"
                      v-if="item.info[0].portrait !== ''"
                      height="24"
                      :src="item.info[0].portrait"
                      alt=""
                    />
                    <img
                      v-else
                      width="24"
                      height="24"
                      src="../../assets/defaultStaffPhoto.png"
                      alt=""
                    />
                    <div class="task-info">
                      <div
                        :class="['news', { readed: item.no_read_num === 0 }]"
                      >
                        <span style="color: red" v-if="item.no_read_num !== 0">
                          [@我的]
                        </span>
                        {{ item.info[0].uname }} {{ item.info[0].content }}
                      </div>
                      <div
                        :class="['time', { readed: item.no_read_num === 0 }]"
                      >
                        {{ item.info[0].time }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="no-msg">
                <div>
                  <img
                    width="74"
                    height="74"
                    src="../../assets/nomsg.png"
                    alt=""
                  />
                  <div style="text-align: center">暂无通知</div>
                </div>
              </div>
              <div class="btm" v-show="showBottom2">
                没有更多数据啦~
              </div>
            </el-tab-pane>
          </el-tabs>

          <el-popover
            popper-class="pop"
            placement="bottom"
            width="200"
            trigger="click"
            v-model="popoverVisible"
          >
            <div class="list" v-loading="poploading">
              <div class="li" @click="markasread(activeName)">
                <i class="el-icon-check"></i> 标记所有为已读
              </div>
              <div class="li" @click="deleteAll(activeName)">
                <i class="el-icon-delete"></i> 删除所有已读消息
              </div>
            </div>
            <!-- <el-button >click 激活</el-button> -->
            <div class="more" slot="reference">
              <i class="el-icon-more"></i>
            </div>
          </el-popover>
        </div>
      </div>
      <div class="info-panel right-info">
        <img
          class="empty"
          v-if="JSON.stringify(taskInfo) == '{}'"
          width="420"
          height="300"
          src="https://g.alicdn.com/teambition/web/teambition/images/inbox-detail-placeholder.8c33e283..png"
          alt=""
        />
        <div class="task-info1" v-else>
          <task-detail
            @getChatList="getChatList($event)"
            @getTaskDetail="getTaskDetail"
            @sendMsg="sendUMsg($event)"
            @editChat="editChat($event)"
            @delChat="delChat($event)"
            :info="taskInfo"
            :propsLoading="infoLoading"
            :flag="true"
            :chatList="chatList"
            :atStaffList="atStaffList"
            ref="detail"
          ></task-detail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskDetail from '../Task/TaskDetail.vue'
// import { connectWebsocket } from '@/utils/websocket.js'
import {
  getWebsocketMessage,
  getClassMsgNum,
  getUnreadList,
  getRoundList,
} from '@/api/ws'
import lodash from 'lodash'
import { read, deleteAll } from '@/api/taskall'
import { infoTaskA } from '@/api/taskall'
import { getList, getAdd, getAt, getEdit, getWithdraw } from '@/api/chat.js'
import { getNavList } from '@/api/home'

// import func from 'vue-editor-bridge'
export default {
  components: {
    TaskDetail,
  },

  data() {
    return {
      showBottom1: false,
      showBottom2: false,
      activeName: 'first',
      idx: 0,
      popoverVisible: false,
      allMsg: '',
      allMsgCache: [],
      unreadMsg: '',
      unreadMstCache: [],
      userData: '',
      userDataCache: [],
      loading: false,
      markTaskIds: [],
      deleteIds: [],
      taskInfo: {},
      chatList: [],
      atStaffList: [],
      q: '',
      allMsgIds: [],
      instantMsgIds: [],
      unreadMsgIds: [],
      chatListLoading: false,
      isBoxActive: -1,
      infoLoading: false,
      poploading: false,
      showBottom: false,
      allmsgcount: '..',
      userDatacount: 0,
      pagequery: { page: 1, limit: 15 },
      unreadQuery: { page: 1, limit: 15 },
      userQuery: { page: 1, limit: 15 },
      //   entire: sessionStorage.getItem('radio'),
    }
  },
  created() {},
  mounted() {
    this.getWsMessage()
    this.fscrollEvent()
    this.sscrollEvent()
    this.thscrollEvent()
  },
  watch: {
    socketMsgs: {
      //处理接收到的消息
      handler: function() {
        let that = this
        that.toDoSocket(that.socketMsgs.data)
      },
    },
    groupids() {
      let that = this
      //  console.log(this.groupids)
      that.$websocket.commit('setGroupids', this.groupids)
    },
    chatList(oldVal, newVal) {
      //   console.log('chatlist changed')
      if (oldVal.length !== newVal.length) {
        this.$refs.detail.scrollToBottom()
      }
    },
  },
  computed: {
    //监听接收到的消息
    socketMsgs() {
      return this.$websocket.getters.socketMsgs
    },
    groupids() {
      if (this.activeName === 'first') {
        return this.allMsgIds
      } else if (this.activeName === 'second') {
        return this.unreadMsgIds
      } else {
        return this.instantMsgIds
      }
    },
    entire() {
      return window.sessionStorage.getItem('radio')
    },
    unreadMsgOfAll() {
      let list = this.allMsg.data
      return list.filter((item) => {
        return item.no_read_num !== 0
      })
    },
  },
  methods: {
    fscrollEvent() {
      let panel = document.querySelector('#pane-first1')
      panel.addEventListener(
        'scroll',
        lodash.debounce(this.firstScrollEvent, 500),
        true
      )
    },
    sscrollEvent() {
      let panel = document.querySelector('#pane-sec1')
      panel.addEventListener(
        'scroll',
        lodash.debounce(this.secdScrollEvent, 500),
        true
      )
    },
    thscrollEvent() {
      let panel = document.querySelector('#pane-third1')
      panel.addEventListener(
        'scroll',
        lodash.debounce(this.thirdScrollEvent, 500),
        true
      )
    },
    thirdScrollEvent(e) {
      if (
        e.target.scrollTop + e.target.clientHeight >
        e.target.scrollHeight - 150
      ) {
        //-50距离底部50px是触发以下内容
        console.log('触发了')
        this.userQuery.page++
        getRoundList(this.userQuery).then((res) => {
          console.log(res.data.data.data)
          if (res.data.data.data.length === 0) {
            this.showBottom2 = true
            this.cancelthirdScroll()
            return
          }
          this.userData = [...this.userData, ...res.data.data.data]
          this.userDataCache = this.userData

          e.target.scrollTop -= 230
          this.$forceUpdate()
        })
      }
    },
    cancelthirdScroll(e) {
      console.log('remove')
      let panel = document.querySelector('#pane-third1')

      panel.removeEventListener('scroll', this.thirdScrollEvent)
    },
    secdScrollEvent(e) {
      if (
        e.target.scrollTop + e.target.clientHeight >
        e.target.scrollHeight - 150
      ) {
        //-50距离底部50px是触发以下内容
        console.log('触发了')
        this.unreadQuery.page++
        getUnreadList(this.unreadQuery).then((res) => {
          console.log(res.data.data)
          if (res.data.data.length === 0) {
            this.showBottom1 = true
            this.cancelsScroll()
            return
          }
          this.unreadMsg = [...this.unreadMsg, ...res.data.data]
          this.unreadMstCache = this.unreadMsg
          e.target.scrollTop -= 200
          this.$forceUpdate()
        })
      }
    },
    cancelsScroll() {
      console.log('remove')
      let panel = document.querySelector('#pane-sec1')

      panel.removeEventListener('scroll', this.secdScrollEvent)
    },
    firstScrollEvent(e) {
      if (
        e.target.scrollTop + e.target.clientHeight >
        e.target.scrollHeight - 150
      ) {
        //-50距离底部50px是触发以下内容
        console.log('触发了')
        this.pagequery.page++
        getWebsocketMessage(this.pagequery).then((res) => {
          console.log(res.data.data.data)
          if (res.data.data.data.length === 0) {
            this.showBottom = true
            this.cancelfScroll()
            return
          }
          this.allMsg = [...this.allMsg, ...res.data.data.data]
          this.allMsgCache = this.allMsg

          e.target.scrollTop -= 150
          this.$forceUpdate()
        })
      }
    },
    cancelfScroll() {
      console.log('remove')
      let panel = document.querySelector('#pane-first1')

      panel.removeEventListener('scroll', this.firstScrollEvent)
    },

    async delChat(id) {
      const { data: res } = await getWithdraw({ id: id })
      console.log(res)
      if (res.code === 0) {
        this.getNavList()
        this.getChatList()
        this.$message.success('撤回消息成功！')
      } else {
        this.$message.error(res.message)
      }
    },
    // 获取左侧导航菜单列表
    async getNavList() {
      let info = await getNavList()
      info = info.data.data
      this.$store.commit('getNavigation', info)
      //   console.log(111);
    },
    toDoSocket: function(data) {
      //   console.log(data)
      //  console.log(data.indexOf('logout'))
      if (data.indexOf('logout') === -1) {
        data = JSON.parse(data)
        //       console.log('木有logout')
        if (data.type === 'init') {
          ///     console.log(this.$websocket)
          let ids = JSON.stringify(this.groupids)
          //   console.log(ids)
          let obj = {
            fromid: sessionStorage.getItem('uid'),
            type: 'bind',
            groupids: ids,
          }
          let strObj = JSON.stringify(obj)
          this.$websocket.state.ws.send(strObj)
          this.getWsMessage()
        } else if (data.type === 'text') {
          // 发消息
          //     console.log('text')
          this.getWsMessage()
        }
      } else {
        this.$websocket.commit('reconnect')
        console.log('重连咯')
      }

      if (data.success) {
        //     console.log(data)
      }
    },
    // 定位信息
    nextMsg() {
      console.log('msgclick')
      let height = document.querySelector('#pane-first1').scrollHeight
      console.log(height)
      let msgs = document.querySelectorAll('.hasMsg')
      console.log(msgs)
      let offsetArr = []
      msgs.forEach((node) => {
        offsetArr.push(node.offsetTop)
      })
      console.log(offsetArr)

      if (this.idx < offsetArr.length) {
        document.querySelector('#pane-first1').scrollTop = offsetArr[this.idx]
        this.idx++
      } else {
        this.idx = 0
        document.querySelector('#pane-first1').scrollTop = offsetArr[this.idx]
        this
      }
    },
    // 获取@的人员列表
    async getAtList() {
      const { data: res } = await getAt({ id: this.taskInfo.id })

      //  console.log(res)
      if (res.code === 0) {
        this.atStaffList = res.data
        this.$refs.detail.clearInput()
      } else {
        this.$message.error(res.message)
      }
    },

    // 发送信息
    async sendUMsg(q) {
      //  console.log(q)

      let that = this
      //  q.fromid = sessionStorage.getItem('uid')
      const strq = JSON.stringify(q)
      let groupid = q.pid
      let obj = {
        type: 'say',
        data: strq,
        groupid: groupid,
      }

      let qq = JSON.stringify(obj)

      console.log(that.$websocket.state.ws)
      if (that.$websocket.state.ws.readyState === 1) {
        that.$websocket.state.ws.send(qq)
      } else {
        if (that.$websocket.state.ws.readyState === 0) {
          console.log('网络缓慢，正在尝试发送中...')
          that.$message.error('网络缓慢，正在尝试发送中...')
          //  that.$websocket.commit('reconnect')
        } else if (
          that.$websocket.state.ws.readyState === 2 ||
          that.$websocket.state.ws.readyState === 3
        ) {
          console.log('网络缓慢，正在尝试发送中...')
          that.$message.error('网络缓慢，正在尝试发送中...')
          //  that.$websocket.commit('reconnect')
        }
      }
      //   that.$websocket.state.ws.send(qq)
      this.q = q
      const { data: res } = await getAdd(this.q)
      //     console.log(res)

      if (res.code === 0) {
        that.getWsMessage().then(() => {
          that.$message.success('发布消息成功')
          this.$store.commit('updateTextCache', {
            text: '',
            id: this.taskInfo.id,
          })
        })
      } else {
        that.$message.error(res.message)
      }
    },
    // 获取聊天列表
    async getChatList(entire = 1) {
      //   console.log('chatlist')

      if (
        window.sessionStorage.getItem('radio') === '0' ||
        window.sessionStorage.getItem('radio') == '1'
      ) {
        //      console.log('0 1')
        const { data: res } = await getList({
          pid: this.taskInfo.id,
          entire: Number(window.sessionStorage.getItem('radio')),
        })
        if (res.code === 0) {
          this.chatList = res.data
        }
      } else if (window.sessionStorage.getItem('radio') === '2') {
        // 仅附件
        //       console.log('2')
        const { data: res } = await getList({
          pid: this.taskInfo.id,
          entire: 1,
        })
        if (res.code === 0) {
          if (res.data && res.data.length !== 0) {
            this.chatList = res.data.filter((item) => {
              return item.file !== null
            })
          }
        }
      }

      // console.log(res)
    },
    handleClick(tab, event) {
      // console.log(tab, event)
    },
    showPopover() {},
    generateIds(msg) {
      let ids = []
      if (msg && msg.length !== 0) {
        msg.forEach((item) => {
          ids.push(item.id)
        })
      }
      return ids
    },
    async getMsgNum() {
      const { data: res } = await getClassMsgNum()
      console.log(res.data)
      this.allmsgcount = res.data
      this.$store.commit('setMsgCount', this.allmsgcount)
    },
    async getWsMessage() {
      if (this.unreadMstCache.length !== 0 && this.activeName === 'second') {
        this.unreadMsg = this.unreadMstCache
        this.getNavList()
        this.getChatList().then(() => {
          this.infoLoading = false
        })

        this.loading = false
        return
      } else if (this.allMsgCache.length !== 0 && this.activeName === 'first') {
        this.allMsg = this.allMsgCache
        this.getNavList()
        this.getChatList().then(() => {
          this.infoLoading = false
        })

        this.loading = false
        return
      } else if (
        this.userDataCache.length !== 0 &&
        this.activeName === 'third'
      ) {
        this.userData = this.userDataCache
        this.getNavList()
        this.getChatList().then(() => {
          this.infoLoading = false
        })

        this.loading = false
        return
      } else {
      }
      this.loading = true
      getWebsocketMessage(this.pagequery).then(async (res) => {
        this.getMsgNum()
        this.instantMsg = res.data.data.data

        this.allMsg = res.data.data.data

        const { data: r1 } = await getUnreadList(this.unreadQuery)
        const { data: r2 } = await getRoundList(this.userQuery)
        console.log(r1.data)
        this.unreadMsg = r1.data
        this.userData = r2.data.data
        this.userDatacount = r2.data.count

        this.allMsgIds = this.generateIds(this.allMsg)
        this.unreadMsgIds = this.generateIds(this.unreadMsg)
        this.instantMsgIds = this.generateIds(this.userData)

        this.getNavList()
        this.getChatList().then(() => {
          this.infoLoading = false
        })

        this.loading = false
      })

      this.loading = false
    },

    //标记所有为已读
    async markasread(activeName) {
      // console.log(activeName)
      this.markTaskIds = []
      if (activeName === 'first') {
        //全部消息
        let allmsg = ''
        getWebsocketMessage({
          limit: 9999999,
          page: 1,
        }).then(async (res) => {
          allmsg = res.data.data
          console.log(allmsg)
          if (allmsg.data.length !== 0) {
            allmsg.data.forEach((item) => {
              this.markTaskIds.push(item.id)
            })
            const ids = JSON.stringify(this.markTaskIds)
            this.poploading = true
            const res = await read({ ids: ids })
            //    console.log(res)
            if (res.data.code === 1) {
              this.$message.success('标记成功！')
              this.popoverVisible = false
              this.getWsMessage()
            } else {
              this.$message.error(res.data.message)
            }
            this.poploading = false
          } else {
            this.$message.warning('暂无通知')
            return
          }
        })
      } else if (activeName === 'second') {
        // 未读消息
        let unreadMsg = ''

        getUnreadList({
          page: 1,
          limit: 99999,
        }).then(async (res) => {
          unreadMsg = res.data.data
          if (unreadMsg.data.length !== 0) {
            unreadMsg.data.forEach((item) => {
              this.markTaskIds.push(item.id)
            })
            const ids = JSON.stringify(this.markTaskIds)
            this.poploading = true
            const res = await read({ ids: ids })
            //    console.log(res)
            if (res.data.code === 1) {
              this.$message.success('标记成功！')
              this.popoverVisible = false
              this.getWsMessage()
            } else {
              this.$message.error(res.data.message)
            }
            this.poploading = false
          } else {
            this.$message.warning('暂无通知')
            return
          }
        })
      } else {
        // @我的
        let userData = ''
        getRoundList({
          page: 1,
          limit: 99999,
        }).then(async (res) => {
          userData = res.data.data
          if (userData.data.length !== 0) {
            userData.data.forEach((item) => {
              this.markTaskIds.push(item.id)
            })
            const ids = JSON.stringify(this.markTaskIds)
            this.poploading = true
            const res = await read({ ids: ids })
            //    console.log(res)
            if (res.data.code === 1) {
              this.$message.success('标记成功！')
              this.popoverVisible = false
              this.getWsMessage()
            } else {
              this.$message.error(res.data.message)
            }
            this.poploading = false
          } else {
            this.$message.warning('暂无通知')
            return
          }
        })
      }
    },
    // 删除所有已读信息
    async deleteAll(activeName) {
      // console.log(activeName)
      this.deleteIds = []
      if (activeName === 'first') {
        //全部消息
        let allmsg = ''
        getWebsocketMessage({
          limit: 9999999,
          page: 1,
        }).then(async (res) => {
          allmsg = res.data.data
          console.log(allmsg)
          if (allmsg.data.length !== 0) {
            allmsg.data.forEach((item) => {
              if (item.no_read_num === 0) {
                // 已读
                this.deleteIds.push(item.id)
              }
            })
            const ids = JSON.stringify(this.deleteIds)
            this.poploading = true
            const res = await deleteAll({ ids: ids })
            //    console.log(res)
            if (res.data.code === 1) {
              this.$message.success('删除成功！')
              this.popoverVisible = false
              this.getWsMessage()
            } else {
              this.$message.error(res.data.message)
            }
            this.poploading = false
          } else {
            this.$message.warning('暂无通知')
            return
          }
        })
        //全部消息
        if (this.allMsg.data.length !== 0) {
          this.allMsg.data.forEach((item) => {
            if (item.no_read_num === 0) {
              // 已读
              this.deleteIds.push(item.id)
            }
          })
        } else {
          this.$message.warning('暂无通知')
          return
        }
      } else if (activeName === 'second') {
        // 未读消息
        if (this.unreadMsg.data.length !== 0) {
          this.unreadMsg.data.forEach((item) => {
            if (item.no_read_num === 0) {
              // 已读
              this.deleteIds.push(item.id)
            }
          })
        } else {
          this.$message.warning('暂无通知')
          return
        }
      } else {
        // @我的
        if (this.userData.data.length !== 0) {
          this.userData.data.forEach((item) => {
            if (item.no_read_num === 0) {
              // 已读
              this.deleteIds.push(item.id)
            }
          })
        } else {
          this.$message.warning('暂无通知')
          return
        }
      }
      const ids = JSON.stringify(this.deleteIds)
      const res = await deleteAll({ ids: ids })
      //  console.log(res)
      if (res.data.code === 1) {
        this.$message.success('删除成功！')
        this.getWsMessage()
      } else {
        this.$message.error(res.data.message)
      }
    },
    async editChat(info) {
      const { data: res } = await getEdit(info)
      console.log(res)
      if (res.code === 0) {
        this.getNavList()
        this.getChatList()
        this.$message.success('编辑消息成功')
      } else {
        this.$message.error(res.message)
      }
    },
    async deleteMsg(id) {
      let idArr = [id]
      //console.log(idArr)
      const idJSON = JSON.stringify(idArr)
      const res = await deleteAll({ ids: idJSON })
      // console.log(res)
      if (res.data.code === 1) {
        this.$message.success('删除成功！')
        this.getWsMessage()
      } else {
        this.$message.error(res.data.message)
      }
    },
    async getTaskDetail(id, index, flag, deviceType) {
      this.infoLoading = true

      this.isBoxActive = index

      //  console.log(id)
      const res = await infoTaskA({ id: id })
      // console.log(res)
      if (res.data.code === 1) {
        this.taskInfo = res.data.data
        this.getAtList()
        //   this.handleMsg()
        //this.getChatList()
        this.getWsMessage().then(() => {
          //   this.infoLoading = false
        })
      } else {
        this.$message.error(res.data.message)
      }

      if (flag === true) {
        // console.log(this.$refs.detail)
        if (this.$refs.detail !== undefined) {
          this.$refs.detail.clearFile()
        }
      }
      let textCache = JSON.parse(localStorage.getItem('textCache'))
      if (textCache) {
        this.$store.commit('setTextCache', textCache)
      }
      //   console.log('detail')
      if (this.$refs.detail) {
        this.$refs.detail.sendQuery.content = ''
        if (textCache && textCache.length > 0) {
          textCache.forEach((item) => {
            if (item.id === this.taskInfo.id) {
              if (item.text !== '') {
                console.log(item.text)
                this.$refs.detail.sendQuery.content = item.text
              }
            }
          })
        }
      }
    },
    // 选择要@的人
    async choseStaff() {},
  },
}
</script>

<style scoped>
.info-card {
  box-sizing: border-box;
  width: 100%;
  /* height: 90%; */
  height: calc(100vh - 100px);
  background-color: #e8e8e9;
  padding: 12px;
  border-radius: 15px;
}
.info-card-inner {
  width: 100%;
  height: 100%;
  display: flex;
}

.info-panel {
  border-radius: 15px;
  background-color: #fff;
}
.left-info {
  flex: 1;
  min-width: 0;
  width: 100%;
  margin-right: 12px;
  /* overflow-y: scroll; */
}
.right-info {
  position: relative;
  flex: 2;
  width: 100%;
}
.tabs {
  padding: 10px !important;
  box-sizing: border-box !important;
  position: relative !important;
  /* height: 100%; */
  user-select: none;
  height: calc(100vh - 110px) !important;
}
.tabs .el-tabs__header {
  padding: 0 8px !important;
  margin: 0px !important;
}
.tabs .el-tabs__item {
  padding: 0 10px;
}
.tabs >>> .el-tabs__content {
  height: calc(100vh - 140px) !important;
  overflow-y: overlay !important;
}
.tabs .no-msg {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #808080;
}

.pop {
  padding: 5px 0 !important;
}
.pop .list .li {
  height: 40px !important;
  line-height: 40px;
  cursor: pointer;
  padding-left: 5px;
  box-sizing: border-box;
}
.pop .list .li:hover {
  background-color: #f5f5f5;
}
.more {
  position: absolute;
  right: 14px;
  top: 15px;
  cursor: pointer;
  color: #999;
}
.more:hover {
  color: #409eff;
}
.note-box {
  display: flex;
  font-size: 14px;
  color: black;
  flex-direction: column;
  padding: 12px 0px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
}
.chosen {
  background-color: #f7f7f7;
}
.note-box:hover {
  background-color: #f7f7f7;
}
.note-box:hover .info-tag {
  display: none;
}
.hoverstyle {
  background-color: #f7f7f7;
}

.note-box .close .el-icon-close {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 18px;
  color: #a6a6a6;
  visibility: hidden;
}
.note-box:hover .close .el-icon-close {
  background-color: #f7f7f7;
  visibility: initial;
}
.close .el-icon-close:hover {
  color: #409eff;
}
.note-box .info-tag {
  padding: 3px 7px 3px 7px;
  background-color: #f56c6c;
  color: #fff;
  font-size: 12px;
  border: 3px solid #fff;
  border-radius: 50%;
  font-weight: 700;
  position: absolute;
  z-index: 111;
  text-align: center;
  right: 8px;
  top: 8px;
}
.note-box .note-title {
  display: flex;
  /* width: 100%; */
  color: #a6a6a6;
}
.note-box .project {
  margin-left: 4px;
  margin-bottom: 4px;
}
.note-box .project span {
  margin-left: 1px;
  margin-right: 15px;
}
.note-box .note-title i {
  margin-top: 4px;
  margin-left: 5px;
}
.note-box .note-title span {
  margin-left: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.readed {
  color: #a6a6a6 !important;
}
.note-box .note-info {
  display: flex;
  color: #595959;
  margin-top: 5px;
}
.note-box .project {
  color: #808080;
}
.note-box .time {
  color: #808080;
}
.note-box .note-info img {
  margin-left: 0px;
  margin-right: 5px;
}

.note-box .task-info .time {
  font-size: 14px;
  margin-top: 5px;
}
.note-info .task-info {
  margin-top: 0;
  margin-left: 9px;
}
.right-info .empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.panel-title {
  position: relative;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  user-select: none;
}
.panel-title .count {
  position: absolute;
  right: -32px;
  top: 0;
  height: 20px;
  line-height: 20px;
  padding: 0px 6px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  background-color: #409eff;
  border-radius: 50%;
  border: 2px solid #fff;
}
.task-info1 {
  height: 100%;
  margin-left: 9px;
  margin-top: 9px;
  border-radius: 15px;
}
/*
.task-info {
  overflow-y: scroll;
} */
/* .right-info {
  overflow-y: scroll;
} */

.red-bro1 {
  line-height: 15px;
  text-align: center;
  margin-bottom: 12px;
  /* right: -23px; */
  padding: 2px 6px;
  background-color: #f56c6c;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  /* padding: 9px; */
  text-align: center;
  white-space: nowrap;
  border: 3px solid #fff;
}
.red-bro2 {
  line-height: 14px;
  text-align: center;
  margin-bottom: 12px;

  font-weight: 700;
  /* right: -23px; */
  padding: 3px;
  background-color: #f56c6c;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  /* padding: 9px; */
  text-align: center;
  white-space: nowrap;
  border: 3px solid #fff;
}
.red-bro3 {
  margin-bottom: 12px;

  line-height: 15px;
  text-align: center;

  font-weight: 700;
  /* right: -23px; */
  padding: 4px 1px 3px 1px;
  background-color: #f56c6c;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  /* padding: 9px; */
  text-align: center;
  white-space: nowrap;
  border: 3px solid #fff;
}
.btm {
  text-align: center;
  color: #a6a6a6;
  margin-top: 5px;
}
</style>
