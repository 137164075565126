<template>
  <div class="task-info-container">
    <div class="info-hd" v-if="flag === true">
      <div class="task-tag">
        <img src="../../assets/task.png" alt="" />
        <span>任务</span>
      </div>
      <div class="go" v-if="flag === true">
        <el-tooltip
          class="item1"
          effect="dark"
          content="前往任务面板"
          placement="bottom"
        >
          <div
            class="hoverbox"
            @click="gotoTaskPanel(form.class2, form.class2_name, form.id)"
            @mouseover="changeIndex(1)"
            @mouseout="changeIndexBack(1)"
          >
            <img src="../../assets/right-black.png" alt="" v-show="!showimg1" />
            <img src="../../assets/right-blue.png" alt="" v-show="showimg1" />
          </div>
        </el-tooltip>
      </div>
    </div>
    <el-skeleton
      style="margin-top:20px"
      :rows="10"
      v-if="propsLoading"
      animated
    />
    <div class="info-bd" v-if="!propsLoading">
      <!-- 任务标题/编辑框 -->
      <div class="task-information" v-loading="propsLoading">
        <el-tooltip
          class="item"
          effect="dark"
          content="点击即可编辑"
          placement="top"
        >
          <div v-show="!edit" @click="edit = !edit" class="title-name">
            {{ form.name }}
          </div>
        </el-tooltip>

        <el-input
          v-focus
          v-model="form.name"
          class="input-title"
          type="textarea"
          v-show="edit"
          @blur="changeName"
        ></el-input>
        <div class="title-name-his">{{ form.last_name }}</div>
      </div>
      <!-- 任务信息 -->
      <div class="tag-list">
        <div class="edit-task">
          <i @click="openEdit" class="el-icon-edit"></i>
        </div>
        <el-row class="row">
          <el-col class="col-half" :span="12">
            <span style="margin-right: 60px" class="first"
              ><i class="el-icon-s-promotion"></i> 状态
            </span>
            <span class="minibtn" v-if="info.state != 1">
              <el-button
                type="warning"
                size="mini"
                @click="changeState"
                v-if="info.state == 0"
                >完成任务</el-button
              >
              <el-button
                type="success"
                size="mini"
                v-if="info.state == 2"
                disabled
                >已完成</el-button
              >
            </span>
            <el-button
              type="primary"
              size="mini"
              v-if="info.state === 1"
              @click="openTask"
              >开始任务</el-button
            >
          </el-col>
          <el-col class="col-half" :span="12">
            <span style="margin-right: 20px" class="first"
              ><i class="el-icon-star-off"></i> 优先级</span
            >

            <el-popover
              v-model="popshow1"
              placement="bottom"
              title="更改优先级"
              width="300"
            >
              <div class="intelligent-select1">
                <el-select
                  size="medium"
                  :popper-append-to-body="false"
                  @change="changePrio"
                  v-model="form.priority"
                  style="width: 100%"
                  placeholder="选择优先级"
                >
                  <el-option
                    v-for="item in section"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <!-- <div style="display: flex; justify-content: end">
                  <el-button size="mini" @click="changePrio()" type="success"
                    >确定</el-button
                  >
                </div> -->
              </div>
              <span slot="reference">
                <el-tag
                  style="cursor: pointer;width: 70px;text-align: center;"
                  effect="dark"
                  type="info"
                  v-if="info.priority === '较低'"
                  size="medium"
                  >{{ info.priority }}</el-tag
                >
                <el-tag
                  style="cursor: pointer;width: 70px;text-align: center;"
                  effect="dark"
                  v-if="info.priority === '普通'"
                  size="medium"
                  >{{ info.priority }}</el-tag
                >
                <el-tag
                  style="cursor: pointer;width: 70px;text-align: center;"
                  effect="dark"
                  type="warning"
                  v-if="info.priority === '紧急'"
                  size="medium"
                  >{{ info.priority }}</el-tag
                >
                <el-tag
                  style="cursor: pointer;width: 70px;text-align: center;"
                  effect="dark"
                  type="danger"
                  v-if="info.priority === '非常紧急'"
                  size="medium"
                  >{{ info.priority }}</el-tag
                >
              </span>
            </el-popover>
          </el-col>
        </el-row>
        <div class="task-field-again">
          <div class="left">
            <div><i class="el-icon-s-custom"></i> 负责人</div>
            <div><i class="el-icon-s-check"></i> 执行者</div>
            <div><i class="el-icon-user"></i> 参与者</div>
            <div><i class="el-icon-circle-check"></i> 已查阅</div>
            <div><i class="el-icon-remove-outline"></i> 未查阅</div>
            <div><i class="el-icon-time"></i> 时间</div>
            <div><i class="el-icon-menu"></i> 项目</div>
          </div>

          <div class="right">
            <div>
              <el-tag type="danger" size="medium" effect="plain">{{
                info.u_name
              }}</el-tag>
            </div>
            <div>
              <el-tag
                effect="plain"
                size="medium"
                type="warning"
                v-for="item in enoughUsers"
                :key="item.id"
                >{{ item.name }}</el-tag
              >
              <el-tooltip
                effect="dark"
                :content="usersContent"
                placement="top"
                v-if="info.users_arr && info.users_arr.length > 8"
              >
                <span class="ellipsis-style">...</span>
              </el-tooltip>
            </div>
            <div>
              <el-tag
                effect="plain"
                size="medium"
                type="success"
                v-for="item in enoughParticipants"
                :key="item.id"
                >{{ item.name }}</el-tag
              >
              <el-tooltip
                effect="dark"
                :content="participantsContent"
                placement="top"
                v-if="info.participant_arr && info.participant_arr.length > 8"
              >
                <span class="ellipsis-style">...</span>
              </el-tooltip>
            </div>
            <div>
              <el-tag
                size="medium"
                type="warning"
                v-if="info.examine_b && info.examine_b.length === 0"
                >无</el-tag
              >
              <el-tag
                size="medium"
                v-else
                v-for="item in enoughRead"
                :key="item.index"
                >{{ item }}</el-tag
              >
              <el-tooltip
                effect="dark"
                :content="readContent"
                placement="top"
                v-if="info.examine_b && info.examine_b.length > 8"
              >
                <span class="ellipsis-style">...</span>
              </el-tooltip>
            </div>
            <div>
              <el-tag
                size="medium"
                type="success"
                v-if="info.examine_a && info.examine_a.length === 0"
                >无</el-tag
              >
              <el-tag
                size="medium"
                v-else
                v-for="item in enoughUnread"
                :key="item.index"
                >{{ item }}</el-tag
              >
              <el-tooltip
                effect="dark"
                :content="unreadContent"
                placement="top"
                v-if="info.examine_a && info.examine_a.length > 8"
              >
                <span class="ellipsis-style">...</span>
              </el-tooltip>
            </div>
            <div>
              <!-- <span
                >{{ timeFilter(info.add_time) }} -
                {{ timeFilter(info.del_time) }}</span
              > -->
              <span>
                <el-tag size="medium" type="info">{{
                  timeFilter(info.add_time)
                }}</el-tag>
                -
                <el-tag size="medium" style="margin-left: 4px;" type="info">{{
                  timeFilter(info.del_time)
                }}</el-tag>
              </span>
            </div>
            <div class="project">
              <el-popover
                v-model="popshow"
                placement="bottom"
                title="跨项目移动"
                width="400"
              >
                <div class="move-form">
                  <el-form style="width: 100%" :model="moveform">
                    <el-form-item
                      prop="last_class"
                      label-width="80px"
                      label="选择子项"
                      class="intelligent-select1"
                    >
                      <el-cascader
                        :append-to-body="false"
                        style="width: 100%"
                        size="medium"
                        :options="menuList"
                        :props="optionProps"
                        v-model="moveform.last_class"
                        filterable
                      >
                      </el-cascader>
                    </el-form-item>
                  </el-form>
                  <div style="display: flex; justify-content: end">
                    <el-button size="mini" @click="confirmMove()" type="success"
                      >确定</el-button
                    >
                  </div>
                </div>
                <span slot="reference">{{ info.class_name }}</span>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
      <!-- 任务动态 -->
      <el-collapse
        class="diaobaole"
        @change="handleCollapseChange"
        v-model="activeNames"
      >
        <el-collapse-item name="1">
          <template slot="title">
            <i
              class="el-icon-s-help"
              style="color:#8c8c8c;font-size: 14px;"
            ></i>
            <div class="chatting-title">任务占比</div>
          </template>

          <div class="pie">
            <Pie :info="info.percentage"></Pie>

            <el-button
              style="margin-top: 10px"
              @click="percentageDiaVisible = true"
              type="primary"
              size="mini"
              >编辑任务占比</el-button
            >
          </div>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <i
              class="el-icon-connection"
              style="color:#8c8c8c;font-size: 14px;"
            ></i>
            <div class="chatting-title">任务动态</div>
          </template>

          <div class="task-chatting">
            <div style="margin-bottom: 10px">
              <el-radio-group v-model="chatradio" size="mini">
                <el-radio-button :label="1">全部</el-radio-button>
                <el-radio-button :label="0">最新</el-radio-button>
                <el-radio-button :label="2">仅附件</el-radio-button>
              </el-radio-group>
            </div>
            <!-- 动态内容 -->
            <div v-if="chatList && chatList.length !== 0">
              <div
                v-loading="chatListLoading"
                class="update"
                v-for="item in chatList"
                :key="item.id"
              >
                <!-- 左侧头像 -->
                <div class="avatar-left">
                  <img
                    v-if="item.u_img"
                    :src="item.u_img"
                    v-imgerror
                    width="24"
                  />
                  <img
                    v-else
                    src="../../assets/defaultStaffPhoto.png"
                    width="24"
                  />
                </div>
                <!-- 右侧信息动态 -->
                <div class="info-right">
                  <!-- 名称及操作 -->
                  <div class="name-option">
                    <span class="left">
                      <span class="name">{{ item.u_name }}</span>
                      <span class="hover-style">
                        <span
                          v-if="item.file && item.file.length !== 0"
                          class="download"
                          @click="downloadFile(item)"
                        >
                          下载附件
                        </span>
                        <span @click="reply(item.u_name)" class="reply">
                          回复
                        </span>
                        <span
                          class="edit"
                          @click="editChat(item)"
                          v-if="item.is_edit === 0"
                        >
                          编辑
                        </span>
                        <span
                          @click="del(item.id)"
                          class="del"
                          v-if="item.is_edit === 0"
                        >
                          撤回
                        </span>
                      </span>
                    </span>

                    <span class="update-time">
                      {{ timeFilter(item.time) }}
                    </span>
                  </div>
                  <!-- 文字、文件信息 -->
                  <div class="info-file">
                    <div class="text-info">{{ changeLine(item.content) }}</div>
                    <div
                      class="file"
                      v-if="item.file && item.file.length !== 0"
                    >
                      <div
                        v-for="file in item.file"
                        :key="file.uniqueIdentifier"
                      >
                        <div class="file-box" @click="previewf(file)">
                          <img
                            width="64"
                            height="64"
                            style="border-radius: 10px; margin-right: 5px"
                            :src="file | fileType"
                          />
                          <!-- <img src="../../assets/filetype/others.png" alt="" /> -->
                          <div class="file-info">
                            <div>{{ file.name }}</div>
                            <div>{{ file.size | change }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="none"
                style="
                  margin: 20px 0;
                  font-size: 16px;
                  text-align: center;
                  color: #999;
                "
              >
                加载中...
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 编辑任务动态 -->
    <el-dialog
      v-dialogDrag
      title="编辑动态"
      :visible.sync="editChatDiaVisible"
      class="chatdia"
      width="30%"
    >
      <div class="dia-info">
        <el-input type="textarea" v-model="editChatInfo.content"></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editChatDiaVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEdit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 任务占比编辑框 -->
    <el-dialog
      v-dialogDrag
      top="50px"
      title="编辑任务占比"
      :visible.sync="percentageDiaVisible"
      @close="percentageDiaVisible = false"
      append-to-body
    >
      <div class="info-task-sli">
        <div
          v-for="(item, index) in info.percentage"
          :key="index"
          class="sli-name"
        >
          <span>{{ item.name }}</span>
          <el-slider v-model="item.percentage" show-input @input="comChange">
          </el-slider>
        </div>
        <span> {{ allNumber }}% </span>
        <span style="font-size: 12px; color: red" v-show="allNumber > 100">
          任务分配比例异常
        </span>
        <el-button size="small" @click="taskChange">提交任务分配</el-button>
        <el-button size="small" @click="taskCum">给剩余人员分配</el-button>
      </div>
    </el-dialog>
    <!-- 聊天框 -->
    <div class="chat-box">
      <!-- @框 -->
      <div class="at" v-if="ifAt">
        <div class="cross" v-if="ifAt" @click="ifAt = false">
          <i class="el-icon-close"></i>
        </div>

        <el-popover
          id="popover"
          v-model="ifAt"
          ref="popoverRef"
          popper-class="pop1"
          placement="bottom"
          trigger="manual"
        >
          <div class="list" v-if="atStaffList && atStaffList.length !== 0">
            <div
              class="li"
              v-for="item in atStaffList"
              :key="item.id"
              @click="choseStaff(item)"
            >
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div v-else>
            加载中...
          </div>
        </el-popover>
      </div>
      <div class="editor" id="editor">
        <!-- 文件上传 -->
        <uploader
          :file-status-text="statusText"
          :options="options1"
          class="uploader-filex"
          ref="upload"
          @file-success="fileSuccess"
          @file-added="fileAdded"
          @file-removed="removeFile"
          @file-progress="fileProgress"
          @file-error="fileError"
        >
          <uploader-unsupport></uploader-unsupport>

          <uploader-drop class="uploader-file-drop">
            <div class="top">
              <uploader-btn><i class="el-icon-paperclip"></i></uploader-btn>
              <!-- 输入框 -->
              <div v-show="!dropActive" class="info-input">
                <el-input
                  ref="input"
                  id="ipt"
                  type="textarea"
                  placeholder="Enter换行，点击按钮或拖拽文件到灰色方框上传"
                  v-model="sendQuery.content"
                  @input="getCursor"
                  @blur="doSomeCache"
                  @paste.native.capture.prevent="handlePaste"
                >
                  <!-- <template slot="append">

              
            </template> -->
                </el-input>
                <el-button type="primary" @click="sendMsg()" class="primary"
                  >发 送</el-button
                >
              </div>
            </div>

            <div class="paste" v-if="pastFileList && pastFileList.length !== 0">
              <span style="font-weight: 700; margin-right: 20px">剪贴板</span>
              <span class="clear" @click="clearPaste">清空</span>
              <br />
              <span
                style="margin-right: 5px"
                v-for="(item, index) in pastFileList"
                :key="index"
              >
                <i class="el-icon-document-copy"></i>
                <span style="margin-left: 2px">{{ item.name }}</span>
              </span>
            </div>
            <uploader-list v-if="!dropActive" class="file-list">
              <div slot-scope="props">
                <div class="wth" v-for="(file, i) in props.fileList" :key="i">
                  <uploader-file :list="true" :file="file"> </uploader-file>
                </div>
              </div>
            </uploader-list>
          </uploader-drop>
        </uploader>
        <!--  -->
        <!-- <el-upload
          class="upload-demo"
          action="https://oa.campgrounds.cn/api/fileupload/fileUpload"
          :headers="headers"
          drag
          multiple
          :limit="5"
          :file-list="elFileList"
        >
          <i
            style="position: absolute;left:17px;color:#8c8c8c;top:18px"
            class="el-icon-paperclip"
          ></i>
        </el-upload> -->
        <!-- <div class="uploader-dragover">
          <h5>+拖拽文件到这里，直接上传</h5>
        </div> -->
      </div>
    </div>
    <!-- 编辑任务对话框 -->
    <el-dialog
      v-dialogDrag
      top="50px"
      title="编辑任务信息"
      :visible.sync="editDialogVisible"
      @close="editDialogVisible = false"
      append-to-body
    >
      <el-form :rules="editRules" :model="form" label-position="left">
        <el-form-item
          class="intelligent-select1"
          prop="u_id"
          label="负责人"
          :label-width="formLabelWidth"
        >
          <el-select
            :popper-append-to-body="false"
            @change="changePrincipal"
            v-model="form.u_id"
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="users"
          label="执行者"
          class="intelligent-select1"
          :label-width="formLabelWidth"
        >
          <el-select
            :popper-append-to-body="false"
            @visible-change="selectChange2($event)"
            @remove-tag="selectChange3($event)"
            v-model="form.users"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="participant"
          label="参与者"
          :label-width="formLabelWidth"
          class="intelligent-select1"
        >
          <el-select
            :popper-append-to-body="false"
            @visible-change="selectChange2($event)"
            @remove-tag="selectChange3($event)"
            v-model="form.participant"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="start_time"
          label="开始时间"
          :label-width="formLabelWidth"
        >
          <el-date-picker
            disabled
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.add_time"
            type="datetime"
            placeholder="开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          prop="del_time"
          label="结束时间"
          :label-width="formLabelWidth"
        >
          <el-date-picker
            v-if="info.apply == 1"
            @change="changeTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.del_time"
            type="datetime"
            placeholder="结束时间"
          >
          </el-date-picker>
          <el-date-picker
            v-if="info.apply == 2"
            disabled
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.del_time"
            type="datetime"
            placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 预览 -->
    <FileReader v-if="prevShow" :file="previewFile"></FileReader>
  </div>
</template>

<script>
import Pie from '@/components/Pie.vue'

import { saveAs } from 'file-saver'
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import FileReader from '@/components/FileReader/index.vue'
import axios from 'axios'
// import { DomEditor } from '@wangeditor/editor'
import {
  taskEmployee,
  Employee,
  taskmodelAccomplish,
  taskmodelConfirmTask,
  taskmodelEdit,
  prioritySection,
  checkTime,
  moveTask,
} from '@/api/taskall.js'
import { getClass } from '@/api/department.js'
import excel from '../../assets/filetype/excel.png'
import ai from '../../assets/filetype/ai.png'
import psd from '../../assets/filetype/psd.png'
import music from '../../assets/filetype/music.png'
import others from '../../assets/filetype/others.png'
import pdf from '../../assets/filetype/pdf.png'
import pic from '../../assets/filetype/pic.png'
import ppt from '../../assets/filetype/ppt.png'
import procedure from '../../assets/filetype/procedure.png'
import txt from '../../assets/filetype/txt.png'
import unknown from '../../assets/filetype/unknown.png'
import video from '../../assets/filetype/excel.png'
import word from '../../assets/filetype/word.png'
import zip from '../../assets/filetype/zip.png'
import { downloadFile, getWithdraw } from '@/api/chat.js'
import {} from '@/api/chat.js'
export default {
  components: {
    Pie,
    FileReader,
  },
  props: {
    info: Object,
    chatList: Array,
    atStaffList: Array,
    flag: Boolean,
    propsLoading: Boolean,
  },
  data() {
    return {
      previewFile: {
        fileurl: '',
        dialogVisible: false,
        title: '',
      },
      dropActive: false,
      optionProps: {
        expandTrigger: 'hover',
        value: 'id',
        label: 'name',
        children: 'list',
      },
      textCursor: '',
      prevShow: false,
      pastValue: '',
      pastFileList: [],
      isOnProgress: false,
      editDialogVisible: false,
      formLabelWidth: '100px',
      statusText: {
        success: '上传成功',
        error: '出错了',
        uploading: '上传中',
        paused: '暂停中',
        waiting: '等待中',
      },
      showimg1: false,
      popshow: false,
      popshow1: false,
      form: {
        name: '',
        users: [],
        pid: '',
        priority: '',
      },
      edit: false,
      editor: null,
      headers: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      options1: {
        target: 'https://oa.campgrounds.cn/api/fileupload/fileUpload',
        headers: {
          'Accept-Token': window.sessionStorage.getItem('token'),
        },

        chunkSize: 1024 * 1024 * 200, //200MB
        fileParameterName: 'file', //上传文件时文件的参数名，默认file
        singleFile: false, // 启用单个文件上传。上传一个文件后，第二个文件
        testChunks: false, //是否开启服务器分片校验
      },
      activeNames: ['2'],
      ifAt: false,
      content: '', //用户输入内容
      sendQuery: {
        pid: '',
        state: '',
        content: '',
        file: [],
        uid: [],
        appoint_user: [],
      },
      chatradio: window.sessionStorage.getItem('radio'),
      options: [],
      section: [],
      emp: {
        id: '',
      },
      editChatDiaVisible: false,
      editRules: {
        // priority: [
        //   {
        //     required: true,
        //     message: '请选择优先级',
        //     trigger: 'change',
        //   },
        // ],
        // name: [
        //   {
        //     required: true,
        //     message: '请输入任务名称',
        //     trigger: 'blur',
        //   },
        // ],
        // u_id: [
        //   {
        //     required: true,
        //     message: '请选择负责人',
        //     trigger: 'change',
        //   },
        // ],
        // users: [
        //   {
        //     required: true,
        //     message: '请选择执行者',
        //     trigger: 'change',
        //   },
        // ],
        // participant: [
        //   {
        //     required: true,
        //     message: '请选择参与者',
        //     trigger: 'change',
        //   },
        // ],
        // start_time: [
        //   {
        //     required: true,
        //   },
        // ],
        // del_time: [
        //   {
        //     required: true,
        //     message: '请选择结束时间',
        //     trigger: 'change',
        //   },
        // ],
      },
      moveform: {
        // last_class: [info.class1, info.class2, info.class3],
      },
      editChatInfo: {
        id: '',
        content: '',
      },
      initalName: '',
      allNumber: 0,
      percentageDiaVisible: false,
      fileUrlList: [],
      fileList: [],
      oldScrollTop: 0,
      scrollFlag: true,
      Intervall: null,
      menuList: [],
    }
  },
  computed: {
    chatListLoading() {
      if (this.chatList) {
        return false
      } else {
        return true
      }
    },
    enoughParticipants() {
      if (this.info.participant_arr && this.info.participant_arr.length > 8) {
        return this.info.participant_arr.slice(0, 8)
      } else {
        return this.info.participant_arr
      }
    },
    enoughUsers() {
      if (this.info.users_arr && this.info.users_arr.length > 8) {
        return this.info.users_arr.slice(0, 8)
      } else {
        return this.info.users_arr
      }
    },
    enoughRead() {
      if (this.info.examine_b && this.info.examine_b.length > 8) {
        return this.info.examine_b.slice(0, 8)
      } else {
        return this.info.examine_b
      }
    },
    enoughUnread() {
      if (this.info.examine_a && this.info.examine_a.length > 8) {
        return this.info.examine_a.slice(0, 8)
      } else {
        return this.info.examine_a
      }
    },
    usersContent() {
      let str = ''
      this.info.users_arr.forEach((item) => {
        str = str + item.name + ' '
      })
      return str
    },
    participantsContent() {
      let str = ''
      this.info.participant_arr.forEach((item) => {
        str = str + item.name + ' '
      })
      return str
    },
    readContent() {
      let str = ''
      this.info.examine_b.forEach((item) => {
        str = str + item + ' '
      })
      return str
    },
    unreadContent() {
      let str = ''
      this.info.examine_a.forEach((item) => {
        str = str + item + ' '
      })
      return str
    },
  },

  created() {},
  mounted() {
    this.getMultiOptions()

    // setTimeout(() => {
    //   this.html = '<p>请输入内容</p>'
    // }, 1500)
    this.form = this.info
    this.initalName = this.form.name
    this.$emit('getChatList', 1)

    // //监听滚动事件
    // document
    //   .querySelector('.task-info-container')
    //   .addEventListener('scroll', this.scrolling)
    let dropArea = document.getElementById('editor')
    dropArea.addEventListener(
      'drop',
      (e) => {
        this.dropActive = false
        e.stopPropagation()
        e.preventDefault()
      },
      false
    )
    dropArea.addEventListener('dragleave', (e) => {
      e.stopPropagation()
      e.preventDefault()
      this.dropActive = false
    })
    dropArea.addEventListener('dragenter', (e) => {
      e.stopPropagation()
      e.preventDefault()
      this.dropActive = true
    })
    dropArea.addEventListener('dragover', (e) => {
      e.stopPropagation()
      e.preventDefault()
      this.dropActive = true
    })

    //处理输入框缓存逻辑
    //  this.getList()
    // let textCacheArr = JSON.parse(localStorage.getItem('textCache'))
    // console.log(textCacheArr)
    // textCacheArr.forEach((item) => {
    //   if (item.id === this.info.id) {
    //     if (item.text !== '') {
    //       this.sendQuery.content = item.text
    //     }
    //   }
    // })
  },

  filters: {
    change(limit) {
      if (limit) {
        var size = ''
        if (limit < 0.1 * 1024) {
          //小于0.1KB，则转化成B
          size = limit.toFixed(2) + 'B'
        } else if (limit < 0.1 * 1024 * 1024) {
          //小于0.1MB，则转化成KB
          size = (limit / 1024).toFixed(2) + 'KB'
        } else if (limit < 0.1 * 1024 * 1024 * 1024) {
          //小于0.1GB，则转化成MB
          size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
        } else {
          //其他转化成GB
          size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
        }

        var sizeStr = size + '' //转成字符串
        var index = sizeStr.indexOf('.') //获取小数点处的索引
        var dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
        if (dou == '00') {
          //判断后两位是否为00，如果是则删除00
          return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
        }
        return size
      } else {
        return ' '
      }
    },
    fileType(file) {
      // console.log(file)
      var type = file.name.substring(file.name.lastIndexOf('.') + 1)
      type.toLowerCase()
      //    console.log(type)
      if (
        type === 'gif' ||
        type === 'jpg' ||
        type === 'jpeg' ||
        type === 'png' ||
        type === 'bmp' ||
        type === 'webp'
      ) {
        return file.url || pic
      }
      if (
        type === 'mp4' ||
        type === 'm3u8' ||
        type === 'rmvb' ||
        type === 'avi' ||
        type === 'swf' ||
        type === '3gp' ||
        type === 'mkv' ||
        type === 'flv' ||
        type === 'ncm'
      ) {
        return video
      }
      if (
        type === 'mp3' ||
        type === 'wav' ||
        type === 'wma' ||
        type === 'ogg' ||
        type === 'aac' ||
        type === 'flac'
      ) {
        return music
      }
      if (type === 'doc' || type === 'docx') {
        return word
      }
      if (type === 'ppt' || type === 'pptx') {
        return ppt
      }
      if (type === 'txt') {
        return txt
      }
      if (type === 'pdf') {
        return pdf
      }
      if (type === 'ai') {
        return ai
      }
      if (type === 'psd') {
        return psd
      }
      if (type === 'xls' || type === 'xlsx') {
        return excel
      }
      if (type === 'zip' || type === 'rar' || type === 'z' || type === 'arj') {
        return zip
      }
      return unknown
    },
  },
  watch: {
    async info() {
      this.form = this.info
      const { data: res } = await taskEmployee({ id: this.info.pid })
      this.options = res.data
    },
    chatradio(newVal) {
      console.log('emit')

      sessionStorage.setItem('radio', newVal)
      this.$store.commit('setChatRadio', newVal)
      this.$emit('getChatList', newVal)
    },
  },
  updated() {
    // let flag = false
    // let textCache = JSON.parse(localStorage.getItem('textCache'))
    // if (textCache) {
    //   this.$store.commit('setTextCache', textCache)
    // }
    // textCache.forEach((item) => {
    //   if (item.id === this.info.id) {
    //     if (item.text !== '') {
    //       this.sendQuery.content = item.text
    //     }
    //   }
    // })
    // if (this.sendQuery.content !== '') {
    //   console.log('输入框里有信息 进行缓存')
    //   this.$store.commit('updateTextCache', {
    //     text: this.sendQuery.content,
    //     id: this.info.id,
    //   })
    // } else {
    //   console.log('输入框里没有信息')
    //   textCache.forEach((item) => {
    //     if (item.id === this.info.id) {
    //       // this.sendQuery.content = item.text
    //       flag = true
    //       console.log('该任务有缓存 进行更新')
    //       this.sendQuery.content = item.text
    //     }
    //   })
    //   if (!flag) {
    //     console.log('该任务没缓存 也没内容 不用更新')
    //   }
    // }
  },
  beforeDestroy() {},
  destroyed() {
    // console.log('des')
  },
  methods: {
    changePriority() {},
    // 缓存
    doSomeCache() {
      console.log('cache')
      this.$store.commit('updateTextCache', {
        text: this.sendQuery.content,
        id: this.info.id,
      })
    },
    timeFilter(time) {
      // console.log(this.$moment(time).format('LL'))

      return this.$moment(time).format('lll')
    },
    //  移入
    changeIndex(e) {
      this[`showimg${e}`] = true
    },
    // 移出
    changeIndexBack(e) {
      this[`showimg${e}`] = false
    },

    //滚动条保持最底部方法
    scrollToBottom() {
      this.$nextTick(() => {
        let that = this

        document.querySelector(
          '.task-info-container'
        ).scrollTop = this.$el.scrollHeight
      })
    },

    scrolling() {
      let scrollTop = document.querySelector('.task-info-container').scrollTop
      // 更新——滚动前，滚动条距文档顶部的距离
      let scrollStep = scrollTop - this.oldScrollTop
      this.oldScrollTop = scrollTop
      //判断当前是向上or向下滚动
      if (scrollStep < 0) {
        //向上
        // console.log('正在向上滚动')
        this.scrollFlag = false
      } else {
        //    console.log('正在向下滚动')
        this.scrollFlag = true
      }
    },
    handlePaste(e) {
      var clip = e.clipboardData.getData('Text')

      this.pastValue = clip
      this.getCursor()
      // 如果是文字或者空就复制粘贴文字并返回
      if (typeof clip == 'string' && clip !== '') {
        const position = this.textCursor
        if (position === this.sendQuery.content.length) {
          this.sendQuery.content += clip
        } else {
          const content = this.sendQuery.content
          this.sendQuery.content =
            content.slice(0, position) + clip + content.slice(position)
        }
        return
      }

      let f = null
      const items = (e.clipboardData || window.clipboardData).items
      console.log(items.length)
      if (items.length) {
        for (let i = 0; i < items.length; i++) {
          //   if (items[i].type.indexOf('image') !== -1) {
          f = items[i].getAsFile()
          console.log(f)
          let formData = new FormData()
          formData.append('file', f)
          let config = {
            headers: {
              'Accept-Token': window.sessionStorage.getItem('token'),
            },
          }
          axios
            .post(
              'https://oa.campgrounds.cn/api/fileupload/fileUpload',
              formData,
              config
            )
            .then((res) => {
              console.log(res)
              if (res.data.code === 0) {
                this.pastFileList.push({
                  url: res.data.data.url,
                  name: res.data.data.name,
                })
              } else {
                this.$message.warning(res.data.message)
              }
            })
        }
      }
    },
    async downloadFile(item) {
      console.log(item)
      let file = item.file
      //   const res1 = await downloadFile({ chat_id: item.id })
      //   console.log(res1)
      //   const blob = new Blob([res1.data])
      //   console.log(blob)
      //   saveAs(blob)
      let filename
      if (file.length === 1) {
        filename = file[0].name
      }
      axios
        .request({
          type: 'post',
          url: 'https://oa.campgrounds.cn/admin/fileupload/downloadFile',
          responseType: 'blob',
          params: {
            chat_id: item.id,
          },
        })
        .then(async (res) => {
          console.log(res)
          const blob = res.data

          const a = document.createElement('a')
          const url = URL.createObjectURL(blob)
          console.log(url)
          a.href = URL.createObjectURL(blob)
          // console.log(a)
          const time = Date.now()
          console.log(time)
          if (filename) {
            a.setAttribute('download', filename)
          } else {
            a.setAttribute('download', Date.now() + '.zip')
          }
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)

          //   window.location.href = URL.createObjectURL(blob)
        })
    },
    carriageReturn() {
      let e = window.event || arguments[0]
      // 英文下｜中文下： 13 Enter Enter
      // 中文下有文字没进入输入框情况是：299 Enter Enter
      if (e.key == 'Enter' && e.code == 'Enter' && e.keyCode == 13) {
        if (!e.metaKey) {
          // e.metaKey == true证明是ctrl+enter
          e.returnValue = false
          this.sendMsg()
          return false
        } else {
          this.sendQuery.content = this.sendQuery.content + '\n'
        }
      }
    },

    previewf(file) {
      // window.open(u, '_blank')
      this.previewFile = {
        fileurl: file.url,
        dialogVisible: true,
        title: file.name,
      }
      this.prevShow = true
    },
    // 修改颜色
    changeColor(e) {
      //   let res = /@(\S+)\s/g
      //   let b = document.querySelector('.news')
      //   let a
      //   a = this.brief.replace(
      //     res,
      //     "<span style='color:#409EFF'>" + e + '</span>'
      //   )
      //   b.innerHTML = a
      // console.log(this.$refs.input)
    },
    // 获取聊天消息列表
    async getList() {
      //  this.$emit('getChatList', this.entire)
      // this.$parent.getChatList(this.entire)
    },

    fileSuccess(rootFile, file, message, chunk) {
      console.log(file)
      let message1 = JSON.parse(message)
      // console.log(file)
      if (message1.code === 1 || message1.code === 10014) {
        this.$message.error(message1.data)
        // this.fileError(null, null, message)
        let idx1 = this.$refs.upload.files.findIndex((f) => {
          return f.uniqueIdentifier === file.uniqueIdentifier
        })
        this.$refs.upload.files.splice(idx1, 1)
        let idx2 = this.$refs.upload.fileList.findIndex((f) => {
          return f.uniqueIdentifier === file.uniqueIdentifier
        })
        this.$refs.upload.fileList.splice(idx2, 1)

        return
      }
      this.fileList.push(file)
      this.fileUrlList.push({
        url: message1.data.url,
        name: file.name,
        size: file.size,
        type: file.fileType,
        uniqueIdentifier: file.uniqueIdentifier,
      })
    },
    fileAdded(file, fileList) {
      console.log(file, fileList)
    },
    removeFile(rootFile, file) {
      console.log(rootFile, file)

      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].uniqueIdentifier === rootFile.uniqueIdentifier) {
          this.fileList.splice(i, 1)
          //   i = i + 1 //改变下标
          //   this.fileList.length = this.fileList.length - 1
        }
      }
      for (let i = 0; i < this.fileUrlList.length; i++) {
        if (
          this.fileUrlList[i].uniqueIdentifier === rootFile.uniqueIdentifier
        ) {
          this.fileUrlList.splice(i, 1)
          //   i = i + 1 //改变下标
          //   this.fileList.length = this.fileList.length - 1
        }
      }
      // console.log(this.fileList.length)
      if (this.fileList.length == 0) {
        this.isOnProgress = false
      }
    },
    // @功能
    setAt(e) {
      // console.log(e.code)
      // console.log(e.shiftKey)
      //   if (e.code === 'Digit2' && e.shiftKey == true) {
      //     this.ifAt = true
      //   }
      //   if (this.sendQuery.content[this.textCursor - 1] === '@') {
      //     console.log('@@@@@@@@@')
      //   }
      // this.changeColor(this.sendQuery.content)
    },

    choseStaff(item) {
      console.log(this.$refs.input)
      if (this.sendQuery.content.substr(-1) === '@') {
        console.log('最后一个是@')
        this.sendQuery.content = this.sendQuery.content + item.name + ' '
        this.$nextTick(() => {
          this.$refs.input.focus() // 视图出现后使input获取焦点
        })
      } else {
        console.log('最后一个字符不是@')
        const position = this.textCursor

        const content = this.sendQuery.content
        this.sendQuery.content =
          content.slice(0, position) + item.name + ' ' + content.slice(position)

        this.$nextTick(() => {
          // 处理光标位置
          let ipt = document.getElementById('ipt')
          console.log(position)
          ipt.focus()
          //  / this.$refs.input.focus() // 视图出现后使input获取焦点
          ipt.setSelectionRange(
            position + item.name.length + 1,
            position + item.name.length + 1
          )
          //  this.$refs.input.$el.selectionStart = this.$refs.input.selectionEnd = position
        })
      }

      this.ifAt = false
    },

    getCursor() {
      // console.log(this.textCursor)
      this.textCursor = this.$refs.input.$el.children[0].selectionStart
      if (this.sendQuery.content[this.textCursor - 1] === '@') {
        // console.log('@@@@@@@@@')
        this.ifAt = true
      }
      if (this.sendQuery.content.indexOf('@') === -1) {
        // 没有@了
        this.ifAt = false
        this.sendQuery.uid = []
      }
    },
    // 清除参数
    clearInput() {
      this.sendQuery.uid = []
      this.sendQuery.appoint_user = []
      //this.sendQuery.content = ''
    },
    gotoTaskPanel(class2, class2_name, id) {
      let url = `taskmine?id=${class2}&name=${class2_name}`
      // console.log(id)
      sessionStorage.setItem('openid', id)
      this.$router.push(url)
    },
    // 发送信息
    sendMsg() {
      // console.log('sss')
      this.sendQuery.pid = this.info.id
      const reg = /@(\S+)\s/g
      const res = this.sendQuery.content.match(reg)
      // console.log(res)
      if (res == null) {
        this.sendQuery.uid = []
        this.sendQuery.appoint_user = []
      } else {
        // res.forEach((item) => {
        //   item = item.slice(1)
        // })
        for (let i = 0; i < res.length; i++) {
          res[i] = res[i].trim()
          res[i] = res[i].slice(1)
        }
        //  console.log(res)
        this.atStaffList.forEach((item) => {
          res.forEach((i) => {
            if (item.name === i) {
              this.sendQuery.uid.push(item.id)
            }
          })
        })
        console.log(this.sendQuery.uid)
        this.sendQuery.appoint_user = this.sendQuery.uid
      }
      if (this.fileUrlList.length === 0 && this.pastFileList.length === 0) {
        this.sendQuery.state = 1
      } else {
        this.sendQuery.state = 4
        this.sendQuery.file = [...this.fileUrlList, ...this.pastFileList]
      }

      if (this.isOnProgress === true) {
        this.$message.warning(
          '还有正在上传或者未上传完成的文件，请上传完成再发送信息！'
        )
        return
      }
      this.$emit('sendMsg', this.sendQuery, this.entire)
      this.sendQuery = {
        pid: '',
        state: '',
        content: '',
        file: [],
        uid: [],
        appoint_user: [],
      }
      this.$refs.upload.uploader.fileList = []
      this.$refs.upload.uploader.files = []
      this.$refs.upload.fileList = []
      this.$refs.upload.files = []
      this.fileList = []
      this.fileUrlList = []
      this.pastFileList = []
    },
    clearFile() {
      console.log('clear')
      this.$refs.upload.uploader.fileList = []
      this.$refs.upload.uploader.files = []
      this.$refs.upload.fileList = []
      this.$refs.upload.files = []
      this.fileList = []
      this.fileUrlList = []
      this.pastFileList = []
    },
    async getMultiOptions() {
      // 获取任务所属人员列表
      const { data: res } = await taskEmployee({ id: this.info.pid })
      //   console.log(res)
      //   this.emp.id = this.info.id
      //let info = await Employee(this.emp)

      let section = await prioritySection()
      //let menuList = await getMenuList()
      console.log(res)
      this.options = res.data
      this.section = section.data.data

      const { data: res1 } = await getClass()
      for (let key in res1.data) {
        this.menuList.push(res1.data[key])
      }
      this.moveform.last_class = [
        this.info.class1,
        this.info.class2,
        this.info.class3,
      ]
    },
    filesAdded(file, event) {},
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    onChange(editor) {
      const toolbar = DomEditor.getToolbar(editor)

      const curToolbarConfig = toolbar.getConfig()
      console.log(curToolbarConfig.toolbarKeys) // 当前菜单排序和分组
    },
    // 打开编辑任务
    openEdit() {
      this.editDialogVisible = true
    },
    // 开始任务
    async openTask() {
      let form = { id: this.info.id }
      const { data: res } = await taskmodelConfirmTask(form)
      if (res.code === 0) {
        this.$message.success('开始任务成功')
        this.$emit('getTaskDetail', this.info.id)
      } else {
        this.$message.error(res.message)
        this.$emit('getTaskDetail', this.info.id)
      }
    },
    // 改变状态
    async changeState() {
      let form = { id: this.info.id }
      let { data: res } = await taskmodelAccomplish(form)
      if (res.code === 0) {
        this.$message.success('已完成该任务！')
        this.$emit('getTaskDetail', this.info.id)
        if (this.flag === false) {
          this.$emit('getTaskB')
        }
        //this.$parent.getTaskDetail(this.info.id)
      } else {
        this.$message.error(res.message)
        this.$emit('getTaskDetail', this.info.id)
      }
    },
    // 改变优先级
    async changePrio() {
      // if (typeof this.form.priority === 'string') {
      //   this.section.forEach((item) => {
      //     if (item.name === this.form.priority) {
      //       this.form.priority = item.id
      //     }
      //   })
      // }
      let query = {
        id: this.form.id,
        priority: this.form.priority,
      }
      let { data: res } = await taskmodelEdit(query)
      console.log(res)
      if (res.code === 0) {
        this.$message.success('更新优先级成功！')
        this.$emit('getTaskDetail', this.info.id)
      } else {
        this.$message.error(res.message)
        this.$emit('getTaskDetail', this.info.id)
      }
      this.popshow1 = false
    },
    changeLine(info) {
      return info.replace(/↵/g, '\n').trim()
    },
    // 改变任务名
    async changeName() {
      this.edit = false
      this.form.id = this.info.id

      let query = {
        id: this.form.id,
        name: this.form.name,
      }
      if (this.form.name === this.initalName) {
        // console.log('!!!')
        return
      }
      if (this.form.name === '') {
        // console.log('???')
        this.$message.warning('任务名不可以为空')
        this.form.name = this.initalName
        this.$emit('getTaskDetail', this.info.id)

        return
      }
      let { data: res } = await taskmodelEdit(query)
      //  console.log(res)
      if (res.code === 0) {
        this.$message.success('更新任务标题成功')
        this.$emit('getTaskDetail', this.info.id)
      } else {
        this.$message.error(res.message)
        this.$emit('getTaskDetail', this.info.id)
      }
    },
    //改变负责人
    async changePrincipal() {
      let query = {
        id: this.form.id,
        u_id: this.form.u_id,
      }
      let { data: res } = await taskmodelEdit(query)
      //  console.log(res)
      if (res.code === 0) {
        this.$message.success('更新负责人成功')
        this.$emit('getTaskDetail', this.info.id)
      } else {
        this.$message.error(res.message)
        this.$emit('getTaskDetail', this.info.id)
      }
    },
    // 改执行者、参与者
    async selectChange2(e) {
      console.log(e)
      if (e) {
        return false
      } else {
        let query = {
          id: this.form.id,
          participant: this.form.participant,
          users: this.form.users,
        }
        let { data: res } = await taskmodelEdit(query)
        //  console.log(res)
        if (res.code === 0) {
          this.$message.success('更新成功')
          this.$emit('getTaskDetail', this.info.id)
        } else {
          this.$message.error(res.message)
          this.$emit('getTaskDetail', this.info.id)
        }
      }
    },
    async selectChange3(e) {
      let query = {
        id: this.form.id,
        participant: this.form.participant,
        users: this.form.users,
      }
      let { data: res } = await taskmodelEdit(query)
      //  console.log(res)
      if (res.code === 0) {
        this.$message.success('更新成功')
        this.$emit('getTaskDetail', this.info.id)
      } else {
        this.$message.error(res.message)
        this.$emit('getTaskDetail', this.info.id)
      }
    },
    // 改结束时间
    async changeTime() {
      let query = {
        id: this.form.id,
        del_time: this.form.del_time,
      }
      let { data: res } = await checkTime(query)
      //   console.log(res)
      if (res.code === 0) {
        this.$message.success('申请更改结束时间成功,等待审核')
        this.$emit('getTaskDetail', this.info.id)
      } else {
        this.$message.error(res.message)
        this.$emit('getTaskDetail', this.info.id)
      }
    },
    comChange() {
      let info = this.info.percentage
      let number = 0
      //console.log("info");
      //console.log(info);
      info.forEach((item) => {
        number += item.percentage
      })
      this.allNumber = number
    },
    async taskChange() {
      let { data: res } = await taskmodelEdit({
        id: this.info.id,
        percentage: this.info.percentage,
      })
      if (res.code === 0) {
        this.$message.success('更新任务占比成功')
        this.$emit('getTaskDetail', this.info.id)
      } else {
        this.$message.error(res.message)
        this.$emit('getTaskDetail', this.info.id)
      }
    },
    taskCum() {
      if (this.allNumber > 100) {
        return
      }
      let info = this.info.percentage
      let cNumber = 0
      let allNumber = this.allNumber
      info.forEach((item) => {
        if (item.percentage == 0) {
          cNumber++
        }
      })
      let numInfo = 100 - allNumber
      info.forEach((item) => {
        if (item.percentage == 0) {
          item.percentage = Math.floor(numInfo / cNumber)
          //   console.log(numInfo)
          //   console.log(cNumber)
          //   console.log(item.percentage)
        }
      })
    },
    reply(name) {
      this.sendQuery.content = this.sendQuery.content + '@' + name + ' '
    },
    editChat(item) {
      console.log(item)
      this.editChatInfo.content = item.content
      this.editChatInfo.id = item.id
      this.editChatDiaVisible = true
    },
    async confirmEdit() {
      this.$emit('editChat', this.editChatInfo)
      this.editChatDiaVisible = false
    },
    del(id) {
      this.$confirm('确认撤回该消息？')
        .then(async (_) => {
          this.$emit('delChat', id)
          done()
        })
        .catch((_) => {})
    },
    fileProgress(rootFile, file) {
      //console.log(rootFile, file)
      if (file.completed === false || file.paused === true) {
        //文件没上传完 或者暂停了
        this.isOnProgress = true
      } else {
        console.log('else')
        this.isOnProgress = false
      }
    },
    fileError(rootFile, file, response) {
      console.log(response)
    },
    handleCollapseChange(val) {
      // console.log(val)
    },
    clearPaste() {
      this.pastFileList = []
    },
    async confirmMove() {
      let last_class = this.moveform.last_class[
        this.moveform.last_class.length - 1
      ]
      const { data: res } = await moveTask({
        last_class,
        task_id: this.info.id,
      })
      console.log(res)
      if (res.code === 1) {
        this.$message.success('跨项目移动成功！')
        this.popshow = false

        this.$emit('getTaskDetail', this.info.id)
        if (this.flag === false) {
          console.log('updatepage')
          this.$emit('updatepage')
        }
      } else {
        this.$message.error(res.message)
      }
    },
    // tdClose() {
    //   console.log('dia-close')
    // },
  },
}
</script>
<!-- <style src="@wangeditor/editor/dist/css/style.css"></style> -->
<style scoped>
.diaobaole >>> .el-collapse-item__header {
  height: 36px;
  line-height: 36px;
  border-bottom: 0;
  transition: none;
}
.info-bd >>> .el-collapse {
  border: 0 !important;
}
.task-info-container {
  width: 100%;
  height: calc(100% - 69px);

  overflow: scroll;
  box-sizing: border-box;
  border-radius: 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.task-tag {
  width: 73px;
  height: 30px;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
  line-height: 28px;
  color: #409eff;
  background-color: #f2fbff;
}
.info-hd {
  height: 60px;
  margin: 0 10px 0 3px;
  position: relative;
  line-height: 60px;
  border-bottom: 2px solid #e4e7ed;
}

.info-hd .go {
  position: absolute;
  top: 10px;
  right: 0;
}
.info-hd .hoverbox img:hover {
  background-color: #f2fbff;
  cursor: pointer;
}
.ellipsis-style {
  cursor: pointer;
}
.ellipsis-style:hover {
  color: #409eff;
}
.info-bd {
  margin: 0 10px 0 3px;

  /* overflow-y: overlay !important; */
  height: 100%;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.info-bd .title-name:hover {
  background-color: #f7f7f7;
}
.info-bd .title-name-his {
  text-decoration: line-through;
  color: #8c8c8c;
  font-size: 14px;
}
.task-information {
  margin: 10px 0;
  color: #262626;
  font-family: 'PingFangRegular';
}
.tag-list {
  position: relative;
  font-family: 'PingFangRegular';
}
.tag-list .edit-task {
  color: #8c8c8c;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  right: 40px;
  cursor: pointer;
  z-index: 20;
  top: 8px;
}
.tag-list .task-field {
  color: #8c8c8c;
  font-size: 16px;
  margin-bottom: 20px;
}
.tag-list .task-field-again {
  display: flex;

  color: #8c8c8c;
  font-size: 14px;
}
.task-field-again .left {
  margin-right: 10px;
  width: 100px;
}
.task-field-again .left div {
  height: 36px;
  line-height: 36px;
}
.task-field-again .right {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.task-field-again .right div {
  min-width: 0;
  height: 36px;
  line-height: 36px;
}
.task-field .task-field .col-half {
  display: flex;
  justify-content: start;
  align-items: center;
}
.task-field .col-whole {
  display: flex;
  justify-content: start;
  align-items: center;
}
.tag-list .row {
  height: 36px;
  line-height: 36px;
  color: #8c8c8c;
  font-size: 14px;
}
.task-field .col-half .first {
  margin-right: 30px;
}
.task-field .col-whole .first {
  margin-right: 30px;
}
.task-chatting {
  padding-top: 13px;
  /* border-top: 2px solid #e4e7ed; */
}
.task-chatting .update {
  display: flex;
  margin-bottom: 15px;
}
.update .avatar-left {
  margin-right: 10px;
  margin-top: 6px;
}
.update .info-right {
  width: 100%;

  margin-right: 13px;
}
.update .info-right .name-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.update .info-right .name-option .name {
  font-size: 14px;
  color: #8c8c8c;
  height: 32px;
  line-height: 32px;
  margin-right: 5px;
}
.update .info-right .name-option .hover-style {
  font-size: 12px;
  display: none;
  color: #8c8c8c;
}
.update .info-right .name-option .update-time {
  font-size: 12px;

  color: #8c8c8c;
}
.update:hover .info-right .name-option .hover-style {
  display: initial;
}
.info-right .name-option .hover-style .download:hover,
.info-right .name-option .hover-style .reply:hover,
.info-right .name-option .hover-style .edit:hover,
.info-right .name-option .hover-style .del:hover {
  color: #409eff;
  cursor: pointer;
}

.chatting-title {
  font-size: 14px;
  margin: 0 3px;
  font-weight: 500;
  color: #8c8c8c;
  font-family: 'PingFangRegular';
}
.info-file .text-info {
  font-size: 14px;
  /* color: #262626; */
  color: #000;
  white-space: pre-wrap;
  font-family: 'PingFangRegular';
  /* font-family: 'PingFangLight'; */
}

.info-file .file-box {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: start;
  margin: 5px 0;
}
.info-file .file-box:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.file-box .file-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.file-list >>> .uploader-file-status {
  width: 37% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-list >>> .uploader-file-name {
  width: 38% !important;
}
.chat-box {
  /* padding: 20px; */
  /* position: fixed;
  bottom: 50px; */
  /* border: 1px solid #eee; */

  /* width: calc(100% - 21px); */
  width: 100%;
  position: absolute;
  /* height: 125px; */
  /* width: 100%; */
  /* margin-bottom: 10px; */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  /* padding: 25px; */
  box-sizing: border-box;
}

.editor {
  /* border-top: 1px solid #e4e7ed; */
  /* max-height: 177px; */
  /* overflow-y: overlay; */
  /* position: absolute;
   */
  width: 100%;
  left: 50%;
  bottom: 0;
  /* transform: translateX(-50%); */
}

.editor >>> .uploader-drop {
  border: 0;
  /* overflow-y: scroll; */
  border-radius: 10px;
  /* height: 44px; */
  padding-bottom: 0;
  /* padding-bottom: 2px; */
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.editor .info-input {
  /* position: absolute; */

  width: 100%;
  /* left: 0;
  top: 4px;
  z-index: 2; */
  display: flex;
  /* margin-left: 50px; */
  /* margin-top: 7px; */
}
.editor >>> .el-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.info-input >>> .el-textarea__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #eee;
  max-height: 200px;
  min-height: 56px !important;
}
.editor .send-btn {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  position: absolute;
  top: 16px;
  right: 0;
  z-index: 1;
}
.info-input >>> .el-input-group__append {
  color: #fff;
  background: #409eff;
  cursor: pointer;
}

.editor .uploader-file {
  /* overflow: scroll; */
  height: 100%;
  /* margin-top: 13px; */
  padding: 0 !important;
}
.editor .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.uploader-file-drop {
  display: flex;
  flex-direction: column;
}
.uploader-file-drop .top {
  display: flex;
}
.uploader-file-drop .file-list {
  /* flex: 1; */
  /* margin-bottom: 10px; */
  border-radius: 4px;
  margin-top: 10px;
}
.uploader-file-drop .paste {
  width: 100%;
  padding: 3px;
  box-sizing: border-box;
  margin-left: 5px;
  border: 1px dashed #ccc;
  background: transparent;
  margin-top: 10px;
  border-radius: 7px;
  color: #8c8c8c;
  font-size: 12px;
}
.paste .clear {
  color: red;
  cursor: pointer;
}
.editor >>> .uploader-btn {
  border: 0;
  padding: 16px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editor >>> .uploader-dragover {
  text-align: center;
  position: relative;
  line-height: 200%;
  height: 76px;
}

.editor >>> .uploader-dragover::after {
  content: '拖拽文件直接上传';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #e0e0e0;
}
.chatdia >>> .el-dialog__body {
  height: 90px !important;
  padding: 0 17px !important;
}
.el-tag {
  border-radius: 15px;
  margin-right: 3px !important;
}
.at {
  width: 185px;
  position: absolute;
  min-height: 245px;
  overflow: overlay;
  bottom: 51px;
  z-index: 999;
}
.at .pop1 {
  /* padding: 2px 0 !important; */
  position: absolute;
  left: 0;
  bottom: 0px !important;
  z-index: 99;
}
.at >>> .el-popover {
  bottom: 1px !important;
  max-height: 210px !important;
  overflow: overlay;
}
.pop1 .list .li {
  height: 40px !important;
  line-height: 40px;
  cursor: pointer;
  padding: 0 2px;
  box-sizing: border-box;
}
.pop1 .list .li:hover {
  background-color: #f5f5f5;
}
.at .popo {
  position: relative;
}
.at .cross {
  position: absolute;
  right: 18px;
  z-index: 9999;
  color: #8c8c8c;
  bottom: 6px;
  cursor: pointer;
}
.list .cross:hover {
  color: #409eff;
}

.pie {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wth {
  position: relative;
}
.wth .absicon {
  position: absolute;
  right: 9px;
  top: 16px;
  cursor: pointer;
  z-index: 1000;
}
.uploader-filex >>> .uploader-file[status='success'] .uploader-file-remove {
  display: block !important;
}
.uploader-filex {
  position: relative !important;
  height: 100%;
  width: 100%;
}
.upload-demo >>> .el-upload-dragger {
  width: 100%;
  height: 52px;
}
.editor >>> .el-upload {
  width: 100%;
}

.project span:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}
.tag-list >>> .el-button--mini {
  border-radius: 15px !important;
  padding: 7px 10px !important;
  text-align: center;
  font-family: 'PingFangRegular';
}
.task-chatting >>> .el-radio-button:first-child .el-radio-button__inner {
  border-radius: 15px 0 0 15px;
}
.task-chatting >>> .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 15px 15px 0;
}
.intelligent-select1 >>> .el-select-dropdown {
  position: absolute !important;
}
</style>
