import router from '../router/index.js'
import store from '../store/index.js'
//设置addroute
export function setNav(info) {
    let arr = {
        path: "*",
        component: () => import("@/views/Home/404.vue"),
    }
    router.addRoute(arr);
    info.forEach((item) => {
        let data = {
            path: item.path,
            component: () => import(`@/views/Home${item.component}.vue`),
        };
        router.addRoute('Home', data);
        store.commit('getroute', 'true')
    });
    console.log('添加路由完毕');
}
export function setAddRoutes(info) {
    console.log(info);
    let arr = [
        {
            path: "/home",
            redirect: "/index",
            component: () => import("@/views/Home/Home.vue"),
            children: [],
        },
        {
            path: "*",
            component: () => import("@/views/Home/404.vue"),
        }
    ];
    info.forEach((item) => {
        let data = {
            path: item.path,
            component: () => import(`@/views/Home${item.component}.vue`),
        };
        arr[0].children.push(data);
    });
    router.addRoutes(arr);
    store.commit('getroute', 'true')

}
//设置并获取本地存储
export function setlocal(data, name) {
    var seeform = JSON.stringify(data);
    window.sessionStorage.setItem(name, seeform);
    let info = window.sessionStorage.getItem(name);
    return JSON.parse(info);
}
//获取本地存储
export function getlocal(name) {
    let info = window.sessionStorage.getItem(name);
    return JSON.parse(info);
}
//多维转一维
export function change(arr, info) {
    arr.forEach(item => {
        info.push(item)
        if (item.son instanceof Array) {
            change(item.son, info)
        }
    })
    return info
}
//多重数组对象转一维数组对象
export function changeArr(arr) {
    let info = []
    console.log(change(arr, info));
}
//返回面包屑导航
export function getNavn(path) {
    let name = getlocal('navigationData')
    let info = []
    let arr = []
    let nav = change(name, info)
    nav.forEach(item => {
        if (item.path == path) {
            path = item
        }
    });
    if (!(path instanceof Object)) {
        return
    }
    nav.forEach(item => {
        path.hierarchy.forEach(hierarchy => {
            if (item.id == hierarchy) {
                arr.push(item)
            }
        })
    })
    return arr

}

export function getInfo(path) {
    let name = getlocal('navigationData')
    if (!name) {
        return
    }
    let info = []
    let nav = change(name, info)
    nav.forEach(item => {
        if (item.path == path) {
            path = item
        }
    });
    return path.name
}
