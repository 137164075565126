// v-imgerror="备用图地址"
export const imgerror = {
    inserted(el, binding) {
      // el 指令所在的元素  也就是我们的img标签
      // binding 指令的相关信息对象
      el.onerror = function() {
        // console.log('图片加载失败了'), 设置备用图片地址
       // console.log('imgerror')
        el.src = binding.value // binding.value 指令的值 也就是defaultImg
      }
    }
  }
// 自动focus
  export const focus = {
    inserted(el, binding) {
        // el.querySelector('input').focus()
        el.children[0].focus()
    }
  }
// 下载
  export const download =  {
    bind(el, binding) {
        if (binding.value.url) {
            el.addEventListener('click', () => {
                const a = document.createElement('a')
                    //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                const url = binding.value.url // 完整的url则直接使用
                    // 这里是将url转成blob地址，
                fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                    a.href = URL.createObjectURL(blob)
                    console.log(a.href)
                    a.download = binding.value.name || '' // 下载文件的名字
                        // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                    document.body.appendChild(a)
                    a.click()
 
                    //在资源下载完成后 清除 占用的缓存资源
                    window.URL.revokeObjectURL(a.href);
                    document.body.removeChild(a);
                })
            })
        }
    }
}