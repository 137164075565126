<template>
  <div id="app">
    <router-view />
  </div>
</template>
<!-- <script src="https://unpkg.com/element-ui@2.15.13/lib/index.js"></script> -->
<script>
export default {
  data() {
    return {}
  },
  created() {
    //this.initws()
    this.initWebSocket()
    // if (that.$websocket.getters.sendMsgStatus) {
    //   that.$websocket.dispatch('webSocketInit')
    // }
  },
  mounted() {},
  computed: {},
  methods: {
    initws() {
      console.log('init ws')
      let that = this
      if ('WebSocket' in window) {
        //console.log("您的浏览器支持 WebSocket!");

        try {
          //that.ws = new WebSocket(`wss://oa.campgrounds.cn/wss`)
          that.ws = new WebSocket(`ws://192.168.112.193:8282`)
          //   that.ws.onmessage = function(e) {
          //     console.log(e)
          //   }
          that.global.setWs(that.ws)
          console.log(that.ws)

          //   that.$globalWebSocket.setWs(that.ws)
          //   console.log(this.$globalWebSocket.ws)
        } catch (e) {
          console.log('重连中')
        }

        // that.ws.onopen = that.onopen();
        that.ws.onopen = function() {
          console.log('webSocket connect successful')
        }
        // that.glabal.ws.onmessage = function(e, cb) {
        //   console.log(e)

        //   //return cb
        // }
        // that.ws.onmessage = function(e) {
        //   console.log(e)

        //   //   let info = JSON.parse(e.data)
        //   //   if (info.type === 'init') {
        //   //     let obj = {
        //   //       fromid: '222',
        //   //       type: 'bind',
        //   //     }
        //   //     let idobj = JSON.stringify(obj)
        //   //     that.ws.onsend(idobj)
        //   //   }
        // }
        // that.ws.onsend = function(Data) {
        //   that.ws.send(Data)
        // }
        that.ws.onclose = function() {
          // 关闭 websocket
          console.log('webSocket connect closed')
          setTimeout(() => {
            that.initws()
          }, 2000)
        }
      } else {
        // 浏览器不支持 WebSocket
        console.log('您的浏览器不支持 WebSocket!')
      }

      //   this.$connectWebsocket(
      //     'wss://oa.campgrounds.cn/wss',
      //     // 发送
      //     '222',
      //     // 成功拿到后台返回的数据的回调函数
      //     (data) => {
      //       console.log(data)
      //       if (data.indexOf('logout') === -1) {
      //         console.log('成功的回调函数, 接收到的data数据： ', JSON.parse(data))
      //         let idobj = { fromid: sessionStorage.getItem('uid'), type: 'bind' }
      //       } else {
      //         console.log(data)
      //       }

      //       // this.getList()
      //     },
      //     // websocket连接失败的回调函数
      //     () => {
      //       console.log('失败的回调函数')
      //     }
      //   )
    },
    initWebSocket() {
      this.$websocket.dispatch('webSocketInit') //初始化ws
    },
  },
}
</script>
<style>
html,
body {
  width: 100%;
  touch-action: none;
  touch-action: pan-y;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'PingFangRegular', 'Helvetica Neue', Helvetica, 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
#app {
  width: 100%;
  height: 100%;
  /* min-width: 1300px; */
  touch-action: none;
  touch-action: pan-y;
}
.el-breadcrumb {
  margin: none;
}
.el-container {
  height: calc(100vh - 60px);

  box-sizing: border-box;
}
.el-container .home-info .is-vertical {
  margin-left: 10px;
}
.el-main {
  overflow-y: scroll;
}
.el-aside::-webkit-scrollbar {
  width: 5px !important;
  opacity: 0 !important;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 3px;
}
.el-menu--vertical {
  display: none !important;
}
body {
  /*动画属性*/
  animation: myAnimation 3s; /* 规定在3秒内完成动画 */
  animation-iteration-count: infinite; /* 规定动画无限次执行 */
  animation-timing-function: linear; /* 规定动画匀速执行 */
  font-family: 'PingFangRegular', 'Helvetica Neue', Helvetica, 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
/* 
  @keyframes myAnimation {
    form {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  } */
/* 
>>> .el-message .el-message--success {
  border: 1px dashed blue !important;
}
>>> .el-message .el-message--warning {
  border: 1px dashed blue !important;
}
>>> .el-message .el-message--info {
  border: 1px dashed blue !important;
}
>>> .el-message .el-message--error {
  border: 1px dashed black !important;
} */
@media screen and (max-width: 768px) {
  .el-message-box {
    width: 80% !important;
  }
  .el-dialog {
    margin-top: unset !important;
    border-radius: 10px;
  }
  .el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
