import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { getInfo } from '../utils/utils.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imgUrl: 'https://oa.campgrounds.cn/admin/fileupload/imageFile',
    fileUrl: 'https://oa.campgrounds.cn/api/fileupload/fileUpload',
    screenWidth: document.body.clientWidth, // 屏幕宽度
    history: [
       {
        fullPath: "/index",
        name: "/index",
        router:{
            path:'/index',
            query:{
                id:'uniqueIndex',
                name:'消息'
            }
        }
       }
    ],
    info: {},
    isCollapse: false,
    navigationData: [],
    msgCount:'',
    chatradio:1,
    textCache:[],
    taskPageInfo:{
        id: '',
        page: 1,
        limit: 4,
    }
  },
  plugins: [
    createPersistedState({
      reducer(val) {
        return {
          info: val.info,
        }
      },
    }),
  ],
  mutations: {
    setPageInfo(state,data){
        state.taskPageInfo = data
    },
    setTextCache(state,data){
        state.textCache = data
    },
    updateTextCache(state,data){
       // if(data.text==='') return
       let flag = false 
            if(state.textCache.length===0){
                state.textCache.push(data)
            }


            state.textCache.forEach(item=>{
                // if(item.id===data.id){
                //     //找到了任务,更新内容
                //     console.log('同一任务 更新内容');

                //     item['text']=data.text
                //     return

                // }else {
                //     // 没有这个任务 更新
                //     console.log('不同任务 追加内容');
                //     state.textCache.push(data)
                //    return
                // }
                   
                if(item.id===data.id){
                    item['text']=data.text
                    flag = true
                }
                
            })
            if(!flag){
                state.textCache.push(data)
            }
            localStorage.setItem(
                'textCache',
                JSON.stringify(state.textCache)
              )
       
    },
    setChatRadio(state,data){
        state.chatradio = data
    },
    changeColl(state, data) {
      state.isCollapse = data
    },
    getNavigation(state, data) {
      state.navigationData = data
    },
    getroute(state, data) {
    //   console.log(state)
  //   console.log(data);
        
      if (state.history.length >= 4) {
        console.log('大于等于4');
        state.history.splice(1, 1)
      }
     if(data.name=='undefined'||JSON.stringify(data.query)==="{}"||data.path==='/login'||data.path==='/notfound'){
        return
    }
      if (data.index) {
        state.history.splice(data.index, 1)
        return
      } else if (data.index2) {
        // console.log('index2');
        state.history[data.index2] = data.info
        state.history.splice(data.index2, 1)
      } else {
        let flag = 1
        state.history.forEach((item) => {
          // console.log(state.history)
          if (data.query.id) {
            if (item.router.query.id == data.query.id) {
              // console.log('已经有了')
              flag = 0
            }
          }
        })
        if (!flag) {
          return
        }
        // let name = getInfo(data.path);
        let name = data.path
       //  console.log(name)
         if(name==='/index'||name==='undefined'||name==='/login'){return}
        let info = { name: name, router: data }
        state.history.push(info)
        sessionStorage.setItem('history',state.history)
      }
      
    },
    resetHistory(state){
        state.history = [
            {
             fullPath: "/index",
             name: "/index",
             router:{
                 path:'/index',
                 query:{
                     id:'uniqueIndex',
                     name:'消息'
                 }
             }
            }
         ]
    },
    setUserInfo(state, data) {
      state.info = data
    },
    setMsgCount(state,data){
        state.msgCount = data
        window.sessionStorage.setItem('msgCount',data)
    }
  },
  getters:{
    navitype(state){
       
            if (navigator.userAgent.indexOf('Opera') > -1) {
              return 'Opera'
            }
      
            //判断是否Edge浏览器
            if (navigator.userAgent.indexOf('Edg') > -1) {
              return 'Edge'
            }
      
            //判断是否Firefox浏览器
            if (navigator.userAgent.indexOf('Firefox') > -1) {
              return 'firefox'
            }
      
            //判断是否Chrome浏览器
            if (navigator.userAgent.indexOf('Chrome') > -1) {
              return 'Chrome'
            }
      
            //判断是否Safari浏览器
            if (navigator.userAgent.indexOf('Safari') > -1) {
              return 'Safari'
            }
      
            //判断是否IE浏览器
            if (
              navigator.userAgent.indexOf('compatible') > -1 &&
              navigator.userAgent.indexOf('MSIE') > -1 &&
              !isOpera
            ) {
              return 'IE'
            }
            if (navigator.userAgent.indexOf('Trident') > -1) {
              return 'IE'
            }
          
    }
  },
  actions: {},
  modules: {},
})
