<template>
  <div class="trashcan-container">
    <el-dialog
      class="dia"
      @close="diaClose()"
      v-dialogDrag
      title="查看回收箱"
      center
      :visible.sync="trashcanVisible"
    >
      <div class="button-row">
        <el-button
          @click="clear"
          size="mini"
          type="warning"
          icon="el-icon-delete"
          >清空回收箱</el-button
        >
      </div>

      <el-table :data="tableData">
        <el-table-column prop="name" label="任务名"> </el-table-column>
        <el-table-column prop="delete_time" label="删除时间"> </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button
              size="mini"
              type="success"
              @click="recovery(scope.row.id)"
              icon="el-icon-refresh-right"
              >恢复</el-button
            >
            <el-button
              type="danger"
              @click="del(scope.row.id)"
              size="mini"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { binList, clearBin, deleteBinTask, recover } from '@/api/taskall.js'
export default {
  props: {
    trashcanVisible: Boolean,
  },
  data() {
    return {
      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { data: res } = await binList({ list_rows: 15 })
      // console.log(res)
      this.tableData = res.data.data
    },
    diaClose() {
      this.$emit('dialogClose')
    },
    async clear() {
      this.$confirm('确定删除回收箱所有数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { data: res } = await clearBin()
        if (res.code === 1) {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    recovery(id) {
      this.$confirm('确定恢复该任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { data: res } = await recover({ id: id })
        if (res.code === 1) {
          this.$message({
            type: 'success',
            message: '恢复任务成功!',
          })
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    del(id) {
      this.$confirm('确定删除该任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { data: res } = await deleteBinTask({ id: id })
        if (res.code === 1) {
          this.$message({
            type: 'success',
            message: '删除任务成功!',
          })
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .trashcan-container >>> .el-dialog {
    width: 100%;
  }
  .trashcan-container >>> .el-dialog__body {
    padding: 0 10px !important;
  }
}

.button-row {
  display: flex;
  flex-direction: start;
  justify-content: end;
}
.trashcan-container >>> .el-dialog--center .el-dialog__body {
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
