var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-bug-wrap"},_vm._l((_vm.menuList),function(menu){return _c('div',{key:menu.id},[(menu.son || menu.route != 1)?_c('el-submenu',{key:menu.id,attrs:{"index":'route' + menu.id},on:{"mouseenter":function($event){$event.stopPropagation();return _vm.enter.apply(null, arguments)}}},[_c('template',{slot:"title"},[_c('span',{staticClass:"icon-lev",style:({
            backgroundColor: _vm.setBgColor(menu.hierarchy),
            color: _vm.setTextColor(menu.hierarchy),
          }),on:{"click":function($event){$event.stopPropagation();return _vm.changeColl.apply(null, arguments)}}},[_vm._v(" "+_vm._s(menu.hierarchy))]),_c('span',{staticClass:"name",style:(_vm.isCollapse ? 'visibility:hidden' : '')},[_vm._v(" "+_vm._s(menu.name)+" ")]),(menu.no_read_num !== 0)?_c('span',{staticClass:"red-bro",staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(menu.no_read_num)+" ")]):_vm._e()]),_c('MenuTree',{attrs:{"menuList":menu.son}})],2):_c('el-menu-item',{key:menu.index,attrs:{"index":`taskmine?id=${menu.id}&name=${menu.name}`}},[_c('span',{staticClass:"icon-lev",style:({
          backgroundColor: _vm.setBgColor(menu.hierarchy),
          color: _vm.setTextColor(menu.hierarchy),
        }),on:{"click":function($event){$event.stopPropagation();return _vm.changeColl.apply(null, arguments)}}},[_vm._v(_vm._s(menu.hierarchy))]),_c('span',{ref:"name",refInFor:true,staticClass:"name",style:(_vm.isCollapse ? 'visibility:hidden' : '')},[_vm._v(" "+_vm._s(menu.name)+" ")]),(menu.no_read_num !== 0)?_c('span',{staticClass:"red-bro",class:[menu.no_read_num > 99 ? 'red-bro bigger' : 'red-bro']},[_vm._v(_vm._s(menu.no_read_num)+" ")]):_vm._e()])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }