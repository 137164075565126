<!--  -->
<template>
  <div class="login">
    <transition
      :duration="{ enter: 1000, leave: 2000 }"
      enter-active-class="animated lightSpeedIn"
      leave-active-class="animated bounceOutUp"
    >
      <div class="login-main" v-show="flag">
        <!-- 主体 -->
        <div class="login-left">
          <transition enter-active-class="animated tada">
            <img
              src="../../assets/proj.png"
              alt=""
              width="300"
              v-show="imgFlag"
              @mouseover="mouseOver2(imgFlag)"
            />
          </transition>
          <transition enter-active-class="animated rubberBand">
            <div
              class="title"
              v-show="textFlag"
              @mouseover="mouseOver1(textFlag)"
            >
              OA任务中心
            </div>
          </transition>
        </div>
        <div class="login-right">
          <div class="login-form">
            <div class="clearfix">
              <span>登录OA任务中心</span>
            </div>
            <div class="login-info">
              <!-- 表单 -->
              <el-form :model="form" :rules="rules" ref="loginFormRef">
                <el-form-item prop="username">
                  <el-input
                    placeholder="用户名"
                    class="black"
                    v-model="form.username"
                    autocomplete
                  >
                    <template slot="prepend">
                      <div class="el-icon-user-solid"></div>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    placeholder="密码"
                    class="black"
                    v-model="form.password"
                    type="password"
                    autocomplete
                    @keyup.enter.native="login()"
                  >
                    <template slot="prepend">
                      <div class="el-icon-s-cooperation"></div>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item class="item-info">
                  <el-button
                    :loading="loginBtnDisabled"
                    type="info"
                    @click.stop="login"
                    class="login-btn"
                    >登 录</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="phone-main">
      <div class="ele">
        <img class="elelogo" src="../../assets/elep.png" alt="" />
      </div>
      <div class="login-text">OA任务中心</div>
      <div class="login-form-phone">
        <!-- 表单 -->
        <el-form :model="form" :rules="rules" ref="loginFormRef">
          <el-form-item prop="username">
            <el-input
              placeholder="用户名"
              class="black"
              v-model="form.username"
              autocomplete
            >
              <template slot="prepend">
                <div class="el-icon-user-solid"></div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              placeholder="密码"
              class="black"
              v-model="form.password"
              type="password"
              autocomplete
              @keyup.enter.native="login()"
            >
              <template slot="prepend">
                <div class="el-icon-s-cooperation"></div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item class="item-info">
            <el-button
              :loading="loginBtnDisabled"
              type="info"
              @click.stop="login"
              class="login-btn"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getLogin } from '@/api/login'
import { getUserInfo } from '@/api/mine'
import _ from 'lodash'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loginBtnDisabled: false,
      imgFlag: true,
      textFlag: true,
      flag: false,
      checked: false,
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^[A-Za-z0-9]+$/, message: '只能输入大小写字母和数字' },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    mouseOver1() {
      this.textFlag = false
      console.log(1)
      this.$nextTick(() => {
        this.textFlag = true
      })
      console.log(2)
    },
    mouseOver2() {
      this.imgFlag = false
      this.$nextTick(() => {
        this.imgFlag = true
      })
    },

    login: _.debounce(function() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.loginBtnDisabled = true
        let info = await getLogin(this.form)
        if (info.data.code !== 0) {
          this.loginBtnDisabled = false
          return this.$message(info.data.message)
        }
        this.$message({
          message: '登陆成功',
          type: 'success',
          duration: 1000,
        })

        window.sessionStorage.setItem('token', info.data.data.token)
        window.sessionStorage.setItem('name', info.data.data.user)
        // window.sessionStorage.setItem('img', info.data.data.img)
        window.sessionStorage.setItem('uid', info.data.data.uid)
        let that = this
        that.$websocket.commit('setFromid', info.data.data.uid)
        // that.$websocket.commit('setSid', res.data.apiKey)
        that.$websocket.dispatch('webSocketInit')
        window.sessionStorage.setItem('radio', 1)
        var seeform = JSON.stringify(this.form)
        window.sessionStorage.setItem('login', seeform)
        let info2 = await getUserInfo()
        console.log(info2)
        this.$store.commit('setUserInfo', info2.data.data)

        window.sessionStorage.setItem('img', info2.data.data.img?.url)

        if (info.data.exp == 10010) {
          this.$message({
            message: info.message,
            type: 'success',
          })
          this.loginBtnDisabled = false
          this.$router.push('/adduserinfo')
        } else {
          this.flag = false
          this.delay()
          this.loginBtnDisabled = false
        }
      })
    }, 1),

    // login() {},
    delay() {
      let that = this
      var t
      clearTimeout(t)
      t = setTimeout(function() {
        let active = localStorage.getItem('activerouter')
        console.log(active)
        if (active === null || (active && active.indexOf('undefined') !== -1)) {
          console.log('index')
          that.$router.push('/index')
        } else {
          console.log('active')

          that.$router.push(active)
        }
      }, 1000)
    },
    close() {
      let routeData = this.$router.resolve({
        path: '/login',
      })
      window.open(routeData.href, '_self')
    },
    close2() {
      window.close()
    },
    down() {},
    WebSocketTest() {},
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.WebSocketTest()
    this.flag = true
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.login {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('https://oa.campgrounds.cn/uploads/20230428/ec953a6b89706d3e73771558f73497bb.jpg')
    no-repeat center 0px;
  background-position: center 0px;
  background-size: 100% 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-main {
  width: 1000px;
  height: 500px;
  display: flex;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  z-index: 99;
}
.login-left {
  width: 50%;
  height: 500px;
  background: #fff;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  justify-content: center;
  align-items: center;
}
.login-right {
  width: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
}
.login-form {
  width: 320px;
}
.login-btn {
  width: 100%;
}
.clearfix {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  letter-spacing: 4px;
  position: relative;
  top: -60px;
}
.el-button--primary {
  background: #fff;
  color: #409eff;
  border-color: #409eff;
}
.item-info {
  position: relative;
  /* top: 10px; */
}
.login-info {
  /* margin-top: 50px; */
}
.title {
  /* margin-top: 60px; */
  text-align: center;
  color: #3d3434;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 25px;
  cursor: pointer;
}
.phone-main {
  display: none;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.9;
  border: 0;
}
.ele {
  display: flex;
  justify-content: center;
}
.elelogo {
  width: 50%;
  position: relative;
  margin: 20px;
}
.login-text {
  text-align: center;
  color: #3d3434;
  font-size: 18px;
  font-weight: 700;
}
.login-form-phone {
  margin: 25px 50px;
}
>>> .el-input__inner {
  background-color: #f5f7fa;
  border-radius: 95px;
  border: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  box-shadow: 0 0 0 0px;
}
>>> .el-input-group__prepend {
  /* background-color: #ccc; */
  border-radius: 95px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0;
  box-shadow: 0 0 0 0px;
}
>>> .el-button--info {
  border-radius: 99px;
  color: #1f5a94;
  background-color: #f5f7fa;
  border-color: #f5f7fa;
}
>>> .el-button--info:hover {
  border-radius: 99px;
  color: #1f5a94;
  background-color: #eee;
  border-color: #eee;
}
@media screen and (max-width: 768px) {
  .login-main {
    width: 500px !important;
    display: none;
  }
  .login-left {
    display: none;
  }
  .login-right {
    width: 100%;
  }
  .phone-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .login {
    background: none;
  }
}
</style>
